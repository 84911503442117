import React from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";
import { useNavigate } from "react-router-dom";

const ServicePortfolios = ({ api, currentMode }) => {
  const navigate = useNavigate();
  const splideOptions = {
    perPage: 1,
    perMove: 1,
    type: "loop",
    rewind: true,
    keyboard: "global",
    pagination: false,
  };
  return (
    <>
      <section className="main-service-padding" id="serviceportfolio">
        <div className="container">
          <div className="d-flex align-items-center justify-content-center text-center mb-5">
            <h2
              className={`${
                currentMode === "Dark" ? "text-white" : "text-dark"
              }`}
            >
              Our Projects
            </h2>
          </div>
          <Splide options={splideOptions}>
            {api?.map((val, i) => (
              <SplideSlide
                key={i}
                className={`${
                  currentMode === "Dark"
                    ? "bg-black shadow-dark-mode"
                    : "bg-white border"
                } portfoloi-content shadow-sm mt-4 px-3 mb-4 rounded-3`}
                onClick={() =>
                  navigate(`/portfolio/${val.portfolios_id}`, {
                    state: { dataObj: val },
                  })
                }
              >
                <div className="row" style={{cursor: 'pointer'}}>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center justify-content-start">
                    <div className="portfolio-img-content">
                      <img
                        src={`${global.Unial_BackendURL}/nodeassets/${val.portfolios_featuredimages}`}
                        alt="porfolio/img"
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                    <div className="portfolio-text-content d-grid align-items-center">
                      <h2
                        className={
                          currentMode === "Dark" ? "text-white" : "text-black"
                        }
                      >
                        {val.portfolios_title}
                      </h2>
                      <p
                        className={
                          currentMode === "Dark" ? "text-white" : "text-black"
                        }
                      >
                        {val.portfolios_shortdesc}
                      </p>
                      <div className="d-flex align-items-center justify-content-end">
                        <p className="m-0 text-primary">
                          Project Details <span className="fs-2">&rarr;</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </SplideSlide>
            ))}
          </Splide>
        </div>
      </section>
    </>
  );
};

export default ServicePortfolios;

import React, { useEffect, useState } from "react";
import Navbar from "../../components/Header/Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./Career.css";
import "../../GlobalVariables";
import { truncate } from "lodash";
import "./Career.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import bloguser from "../../assets/logo.png";
import CarrerBottomNav from "./CarrerBottomNav";
import { useStateContext } from "../../context/ThemeContext";

const Career = () => {
  const [jobData, setJobData] = useState([]);
  const [pCentric, setpCentric] = useState(true);
  const [librating, setlibrating] = useState(false);
  const [ethical, setethical] = useState(false);
  const [different, setdifferent] = useState(false);
  const [filteredArray, setfilteredArray] = useState([]);
  const navigate = useNavigate();
  const { currentMode } = useStateContext();

  useEffect(() => {
    axios
      .get(`${global.Unial_BackendURL}/career/jobs`)
      .then((res) => setJobData(res.data))
      .catch((err) => console.log(err));
  }, []);

  const array = [
    {
      title: "Centric",
      imgSrc: bloguser,
      text: "Join our team and become part of a company that puts people at the center of everything we do. We're committed to creating a supportive, inclusive, and empowering environment where you can grow and thrive.",
    },
    {
      title: "Liberating",
      imgSrc: bloguser,
      text: "We believe in empowering our employees to take ownership of their work and make a real impact. At our company, you'll have the freedom to innovate, collaborate, and bring your unique perspective to the table. Join us and be part of a culture that values autonomy and creativity.",
    },
    {
      title: "Ethical",
      imgSrc: bloguser,
      text: "Ethics are at the core of everything we do. We're committed to doing business in a way that is honest, transparent, and socially responsible. Join our team and be part of a company that values integrity and strives to make a positive impact on society.",
    },
    {
      title: "Different",
      imgSrc: bloguser,
      text: "We embrace diversity and believe that our differences make us stronger. At our company, you'll work alongside people from all walks of life, with different backgrounds, experiences, and perspectives. Join us and be part of a team that celebrates uniqueness and values inclusivity.",
    },
  ];

  const handlepCen = () => {
    setpCentric(true);
    setlibrating(false);
    setethical(false);
    setdifferent(false);
    const newArray = array.filter((t) => t.title == "Centric");
    setfilteredArray(newArray);
  };
  const handleLib = () => {
    setpCentric(false);
    setlibrating(true);
    setethical(false);
    setdifferent(false);
    const newArray = array.filter((t) => t.title == "Liberating");
    setfilteredArray(newArray);
  };
  const handleEth = () => {
    setpCentric(false);
    setlibrating(false);
    setethical(true);
    setdifferent(false);
    const newArray = array.filter((t) => t.title == "Ethical");
    setfilteredArray(newArray);
  };
  const handleDiff = () => {
    setpCentric(false);
    setlibrating(false);
    setethical(false);
    setdifferent(true);
    const newArray = array.filter((t) => t.title == "Different");
    setfilteredArray(newArray);
  };

  const cardData = [
    {
      title: "Sustainability promise",
      imgSrc:
        "https://dynamicmedia.accenture.com/is/image/accenture/Accenture-sustainability-768?qlt=85&wid=320&ts=1675391908327&fit=constrain&dpr=off",
      text: "Sustainability permeates everything we do. It will create the most powerful force for change in our generation.",
    },
    {
      title: "A world of difference",
      imgSrc:
        "https://dynamicmedia.accenture.com/is/image/accenture/Accenture-Stories-of-Change-768?qlt=85&wid=320&ts=1675391908342&fit=constrain&dpr=off",
      text: "We bring change to life with our clients and in our communities around the world, each and every day.",
    },
    {
      title: "Exceptional growth",
      imgSrc:
        "https://dynamicmedia.accenture.com/is/image/accenture/Accenture-Unparalleled-growth-768?qlt=85&wid=320&ts=1675391908359&fit=constrain&dpr=off",
      text: "FY21 revenues were $50.5B, an 11% increase in local currency. $5.9B cash was returned to shareholders. Find out more on our Investor Relations page.",
    },
    {
      title: "Equality for all",
      imgSrc:
        "https://dynamicmedia.accenture.com/is/image/accenture/Accenture-Bankowosc-i-finanse-image?qlt=85&wid=320&ts=1675391908374&fit=constrain&dpr=off",
      text: "Our commitment to accelerating equality for all starts at the top with our board and CEO and extends across every part of the company.",
    },
    {
      title: "Personalized onboarding",
      imgSrc:
        "https://dynamicmedia.accenture.com/is/image/accenture/Accenture-Personalized-Onboarding-768?qlt=85&wid=320&ts=1675391908389&fit=constrain&dpr=off",
      text: "Our executive onboarding experience is designed to help you transition with ease, with dedicated training and 3 months access to a personal coach.",
    },
    {
      title: "Shared success",
      imgSrc:
        "https://dynamicmedia.accenture.com/is/image/accenture/Accenture-Total-Rewards-768?qlt=85&wid=320&ts=1675391908404&fit=constrain&dpr=off",
      text: "Our reward model is geared for wealth creation with base pay, bonus, and annual equity grants. Our annual stock price growth has been 26% over the past 5 fiscal years, ensuring all our leaders personally benefit from Accenture's success.",
    },
  ];

  useEffect(() => {
    const newArray = array.filter((t) => t.title == "Centric");
    setfilteredArray(newArray);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <CarrerBottomNav />
      <main style={{ marginTop: "7vh" }}>
        <div className="hero-content">
          <div className="hero-content-body">
            <div className="hero-content-text">
              <h1
                className={currentMode === "Dark" ? "text-white" : "text-black"}
              >
                Executive Leadership Careers
              </h1>
              <p
                className={currentMode === "Dark" ? "text-white" : "text-black"}
              >
                Becoming an Accenture Leader unlocks a world of opportunities to
                create real, impactful change.
              </p>
              <button className="hero-team-button">
                <span className="text-primary">Connect With Our Team</span>
                <i class="fas text-primary fa-arrow-right"></i>
              </button>
            </div>
          </div>
          <div className="hero-content-img">
            <img
              src={
                "https://dynamicmedia.accenture.com/is/image/accenture/Accenture-Leadership-Careers-marquee-760?qlt=85&wid=1024&ts=1675391907067&fit=constrain&dpr=off"
              }
              alt="hero/img"
            />
          </div>
        </div>
      </main>

      <section
        className="carreer-card-sections py-5"
        style={{
          background: `${currentMode === "Dark" ? "#000" : "#f2f2f2"}`,
        }}
      >
        <div className="container">
          <div className="d-grid align-items-center carreer-card-title">
            <h1
              className={`fw-bold ${
                currentMode === "Dark" ? "text-white" : "text-black"
              }`}
            >
              We create 360˚ value
            </h1>
          </div>
          <div className="row carreer-card-row">
            {cardData.map((t, i) => (
              <div key={i} className="col-lg-4 col-md-6 col-sm-12 col-12">
                <div
                  className={`card card-border ${
                    currentMode === "Dark"
                      ? "bg-black shadow-dark-mode"
                      : "bg-white"
                  }`}
                >
                  <div className="career-card-img">
                    <img src={t.imgSrc} alt="card/img" />
                  </div>
                  <div className="card-body">
                    <p
                      className={`card-title fw-bold fs-4 ${
                        currentMode === "Dark" ? "text-white" : "text-black"
                      }`}
                    >
                      {t.title}
                    </p>
                    <p
                      className={`card-text ${
                        currentMode === "Dark" ? "text-white" : "text-black"
                      }`}
                    >
                      {truncate(t.text, { length: 100 })}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="pt-3">
        <div className="container">
          <div className=" d-flex justify-content-center">
            <div className="row career-body-head m-4">
              <div
                className={`career-body-head-title col-lg-3 col-md-4 col-sm-6 col-6 ${
                  currentMode === "Dark"
                    ? "bg-black shadow-dark-mode"
                    : "bg-white"
                }`}
                onClick={handlepCen}
              >
                <p
                  style={{
                    margin: 0,
                    padding: "5px",
                    color: pCentric ? "black" : "gray",
                  }}
                >
                  {" "}
                  People Centric
                </p>
                <div
                  className={
                    pCentric
                      ? "d-flex justify-content-center align-items-center"
                      : "d-none"
                  }
                >
                  <div
                    style={{
                      height: "3px",
                      width: "40px",
                      backgroundColor: "black",
                    }}
                  ></div>
                </div>
              </div>
              <div
                className={`career-body-head-title col-lg-3 col-md-4 col-sm-6 col-6 ${
                  currentMode === "Dark"
                    ? "bg-black shadow-dark-mode"
                    : "bg-white"
                }`}
                onClick={handleLib}
              >
                <p
                  style={{
                    margin: 0,
                    padding: "5px",
                    color: librating ? "black" : "gray",
                  }}
                >
                  {" "}
                  Liberating
                </p>
                <div
                  className={
                    librating
                      ? "d-flex justify-content-center align-items-center"
                      : "d-none"
                  }
                >
                  <div
                    style={{
                      height: "3px",
                      width: "40px",
                      backgroundColor: "black",
                    }}
                  ></div>
                </div>
              </div>
              <div
                className={`career-body-head-title col-lg-3 col-md-4 col-sm-6 col-6 ${
                  currentMode === "Dark"
                    ? "bg-black shadow-dark-mode"
                    : "bg-white"
                }`}
                onClick={handleEth}
              >
                <p
                  style={{
                    margin: 0,
                    padding: "5px",
                    color: ethical ? "black" : "gray",
                  }}
                >
                  {" "}
                  Ethical
                </p>
                <div
                  className={
                    ethical
                      ? "d-flex justify-content-center align-items-center"
                      : "d-none"
                  }
                >
                  <div
                    style={{
                      height: "3px",
                      width: "40px",
                      backgroundColor: "black",
                    }}
                  ></div>
                </div>
              </div>
              <div
                className={`career-body-head-title col-lg-3 col-md-4 col-sm-6 col-6 ${
                  currentMode === "Dark"
                    ? "bg-black shadow-dark-mode"
                    : "bg-white"
                }`}
                onClick={handleDiff}
              >
                <p
                  style={{
                    margin: 0,
                    padding: "5px",
                    color: different ? "black" : "gray",
                  }}
                >
                  {" "}
                  Different
                </p>
                <div
                  className={
                    different
                      ? "d-flex justify-content-center align-items-center"
                      : "d-none"
                  }
                >
                  <div
                    style={{
                      height: "3px",
                      width: "40px",
                      backgroundColor: "black",
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="py-5">
        <div className="container">
          <div className="row gy-4 d-flex justify-content-center ">
            {filteredArray.map((t, i) => (
              <>
                <div key={i} className="col-lg-5 col-md-12 col-sm-12 col-12">
                  <div className="d-flex align-items-center justify-content-center">
                    <h1
                      className={`fw-light pt-1 ${
                        currentMode === "Dark" ? "text-white" : "text-black"
                      }`}
                      style={{ fontSize: "35px" }}
                    >
                      {t.text}
                    </h1>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12 col-sm-12 col-12">
                  <div className="d-flex align-items-center justify-content-center">
                    <a
                      href="https://www.youtube.com/watch?v=k69n8P2wadU"
                      target="_blank"
                    >
                      <img
                        className="img-fluid rounded-4 career-middle-section-img"
                        style={{
                          height: "30vh",
                          width: "100%",
                          objectFit: "contain",
                        }}
                        src={t.imgSrc}
                      />
                    </a>
                  </div>
                </div>
              </>
            ))}
          </div>
        </div>
      </section>
      <section className="pt-4 mb-5">
        <div className="container">
          <div className="col-lg-10 col-md-10 col-sm-12 col-12 mx-auto d-grid align-items-center gap-3">
            {jobData?.map((j, i) => (
              <div
                key={i}
                className="job-card w-100"
                style={{
                  border: currentMode === "Dark" ? `1px solid rgba(255,255,255,0.15)` : `1px solid #f3f3f3`,
                  backgroundColor: currentMode === "Dark" ? `#000` : `#f0f0f0`,
                }}
              >
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-grid align-items-center">
                    <h4 className={`job-card-title ${currentMode === "Dark" ? "text-white" : "text-black"}`}>{j.jobpost_title}</h4>
                  </div>
                  <div className="d-grid align-items-center">
                    <button
                      type="button"
                      className="job-card-button"
                      onClick={() =>
                        navigate("/careers/postjob", { state: { dataObj: j } })
                      }
                    >
                      Apply Now
                    </button>
                  </div>
                </div>
                <div className="d-grid align-items-center gap-3">
                  <div className="d-flex align-items-center gap-2">
                    <div className="d-flex align-items-start gap-1">
                      <i
                        class="fas fa-calendar-alt"
                        style={{ color: "#7f0000" }}
                      ></i>
                      <h6 className={`fw-bold fs-6 m-0 p-0 ${currentMode === "Dark" ? "text-white" : "text-black"}`}>Deadline:</h6>
                    </div>
                    <div className="d-grd align-items-center">
                      <p className={`m-0 p-0 fw-normal ${currentMode === "Dark" ? "text-white" : "text-black"}`}>{j.jobpost_deadline}</p>
                    </div>
                  </div>
                  <div className="d-flex align-items-start gap-2">
                    <div className="d-flex align-items-start gap-1">
                      <i
                        class="fas fa-graduation-cap"
                        style={{ color: "#7f0000" }}
                      ></i>
                      <h6 className={`fw-bold fs-6 m-0 p-0 ${currentMode === "Dark" ? "text-white" : "text-black"}`}>
                        Educational Experience:
                      </h6>
                    </div>
                    <div className="d-grid align-items-center">
                      <p className={`m-0 p-0 fw-normal ${currentMode === "Dark" ? "text-white" : "text-black"}`}>
                        {truncate(j.jobpost_shortText, { length: 100 })}
                      </p>
                    </div>
                  </div>
                  <div className="d-flex align-items-center gap-2">
                    <div className="d-flex align-items-start gap-1">
                      <i
                        class="fas fa-briefcase"
                        style={{ color: "#7f0000" }}
                      ></i>
                      <h6 className={`m-0 p-0 fw-bold fs-6 ${currentMode === "Dark" ? "text-white" : "text-black"}`}>Work Experience:</h6>
                    </div>
                    <div className="d-grid align-items-center">
                      <p className="m-0 p-0 ">{j.jobpost_workexperience}</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Career;

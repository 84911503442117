import React, { useEffect, useState } from "react";
import axios from "axios";
// import DropDownComp from "./DropDownComp";
import "./Dropdown.css";

const Dropdown2 = ({ currentMode }) => {
  const [categories, setCategories] = useState([]);
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [activeDiv, setActiveDiv] = useState(false);

  useEffect(() => {
    axios
      .get(`${global.Unial_BackendURL}/service/category`)
      .then((res) => setCategories(res.data))
      .catch((error) => error);
    axios
      .get(`${global.Unial_BackendURL}/applications/unique`)
      .then((res) => setApplications(res.data))
      .catch((error) => error);
  }, []);

  useEffect(() => {
    if (selectedCategory === "All") {
      setFilteredApplications(applications);
    } else {
      setFilteredApplications(
        applications.filter(
          (x) => x.applications_service_id === selectedCategory
        )
      );
    }
  }, [selectedCategory, applications]);

  const handleClick = (cat) => {
    setActiveDiv(true);
    setSelectedCategory(cat.category_id);
  };
  const handleClick2 = () => {
    setActiveDiv(false);
    setSelectedCategory("No");
  };

  const goToBlogs = (t) => {
    // console.log(t, 'link')
    window.open(`${t}`, "_blank");
  };
  return (
    <>
      <div className="container-fluid dropDownContainer">
        <div className="row dropDownTechBox">
          <div className="col-lg-2 col-12 dropDownTechBoxRow">
            <ul
              className={`nav nav-pills navbardropDownPills mb-4 flex-column ${currentMode === "Dark" ? "bg-dark-mode shadow-dark-mode-opacity" : "bg-dark-white"}`}
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item w-100">
                <p
                  className={`nav-link ${currentMode === "Dark" ? "dark-link" : "white-link"}`}
                  onClick={() => setSelectedCategory("All")}
                >
                  All
                </p>
              </li>
              {categories?.map((cat, i) => (
                <li className="nav-item w-100" key={i}>
                  <p
                    className={`nav-link ${currentMode === "Dark" ? "dark-link" : "white-link"}`}
                    onClick={() => setSelectedCategory(cat.category_id)}
                  >
                    {cat.category_name}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          <div className={`col-lg-10 col-12 dropDownTechBoxRow`}>
            <div>
              {selectedCategory === "All" ? (
                <div>
                  {categories.map((val, i) => (
                    <div key={i} className="d-grid align-items-start mt-3">
                      <h6 className={`fw-semibold ${currentMode === "Dark" ? "text-white" : "text-dark"}`}>{val.category_name}</h6>
                      <div className="d-flex align-items-start gap-3 flex-wrap mt-1">
                        {applications.map((app, i) =>
                          val.category_id === app.applications_service_id ? (
                            <div
                              key={i}
                              className="services_box_items d-grid align-items-center"
                              onClick={()=> goToBlogs(app?.applications_shortdesc)}
                              style={{cursor: 'pointer'}}
                            >
                              <img
                                src={`${global.Unial_BackendURL}/nodeassets/${app?.applications_logo}`}
                                className="img-thumbnail shadow-sm mb-1"
                                alt={app.applications_id}
                                height="100px"
                                width="100px"
                              />
                              <p
                                className={`service-text ${currentMode === "Dark" ? "text-white" : "text-dark"}`}
                              >
                                {app?.applications_title}
                              </p>
                            </div>
                          ) : null
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="d-flex align-items-start gap-3 flex-wrap">
                  {filteredApplications.map((app, i) => (
                    <div
                      key={i}
                      className="services_box_items d-grid align-items-center"
                      onClick={() => goToBlogs(app?.applications_shortdesc)}
                    >
                      <img
                        src={`${global.Unial_BackendURL}/nodeassets/${app?.applications_logo}`}
                        className="img-thumbnail shadow-sm mb-1"
                        alt={app.applications_id}
                        height="100px"
                        width="100px"
                      />
                      <p className={`service-text ${currentMode === "Dark" ? "text-white" : "text-dark"}`}>{app?.applications_title}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex">
            <div className={`swipeablediv ${activeDiv ? "move-left" : ""}`}>
              <div className="dropDownTechBoxRow2">
                <ul
                  className="nav nav-pills bg-transparent navbardropDownPills mb-4 flex-column"
                  id="pills-tab"
                  role="tablist"
                >
                  {categories?.map((cat, i) => (
                    <li className={`nav-item w-100 ${currentMode === "Dark" ? "bg-black" : "bg-white"}`} key={i}>
                      <p className={`nav-link ${currentMode === "Dark" ? "text-white" : "text-dark"}`} onClick={() => handleClick(cat)}>
                        {cat.category_name}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className={`swipeablediv2 ${activeDiv ? "move-left" : ""}`}>
              <div className="d-flex justify-content-end align-items-end pt-1 pb-2">
                {" "}
                <i
                  className={`fa-solid fa-arrow-left-long swipeableArrow ${currentMode === "Dark" ? "text-white" : "text-black"}`}
                  style={{ fontSize: 20, paddingRight: "43px" }}
                  onClick={handleClick2}
                ></i>
              </div>
              <div>
                {selectedCategory === "All" ? (
                  <div>
                    {/* {categories.map((val, i) => (
                      <div key={i} className="d-grid align-items-start mt-3">
                        <div className="d-flex align-items-start gap-3 flex-wrap mt-1">
                          {services.map((ser, i) =>
                            val.category_id === ser.services_category ? (
                              <div
                                key={i}
                                className="services_box_items d-grid align-items-center"
                                onClick={goToBlogs(app?.applications_shortdesc)}
                              >
                                <img
                                  src={`${global.Unial_BackendURL}/nodeassets/${app?.applications_logo}`}
                                  className="img-thumbnail shadow-sm mb-1"
                                  alt={app.applications_id}
                                  height="100px"
                                  width="100px"
                                />
                                <p className="service-text">
                                  {app?.applications_title}
                                </p>
                              </div>
                            ) : null
                          )}
                        </div>
                      </div>
                    ))} */}
                  </div>
                ) : (
                  <div
                    className="d-flex align-items-start flex-wrap"
                    style={{ width: "330px" }}
                  >
                    {filteredApplications?.map((app, i) => (
                      <div
                        key={i}
                        className="services_box_items d-grid align-items-center"
                        onClick={() => goToBlogs(app?.applications_shortdesc)}
                      >
                        <img
                          src={`${global.Unial_BackendURL}/nodeassets/${app?.applications_logo}`}
                          className="img-thumbnail shadow-sm mb-1"
                          alt={app.applications_id}
                          height="100px"
                          width="100px"
                        />
                        <p className={`service-text  ${currentMode === "Dark" ? "text-white" : "text-black"}`}>
                          {app?.applications_title}
                        </p>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropdown2;

import React, { useEffect } from "react";
import { useState } from "react";
import { Helmet } from "react-helmet-async";
import { useLocation } from "react-router-dom";
import Navbar from "../../components/Header/Navbar/Navbar";
import Footer from "../Footer/Footer";
import axios from 'axios';

const JobPostPage = () => {
  const [nameState, setNameState] = useState("");
  const [emailState, setEmailState] = useState("");
  const [phoneState, setPhoneState] = useState("");
  const [cvFile, setCVFile] = useState([]);
  const [preference, setPreference] = useState("");
  const [openState, setOpenState] = useState('');

  const ifData = useLocation();
  const item = ifData.state.dataObj;
  //   console.log(item);
  const {
    jobpost_id,
    jobpost_metaKeywords,
    jobpost_metaDescription,
    jobpost_metaTitle,
    jobpost_title
  } = item;
  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log({nameState, emailState, phoneState, cvFile, preference});
    const formData = new FormData();
    formData.append('candidate_name', nameState);
    formData.append('candidate_email', emailState);
    formData.append('candidate_phone', phoneState);
    formData.append('candidate_cv', cvFile);
    formData.append('candidate_preference', preference);
    formData.append('candidate_jobpostedId', jobpost_id);
    formData.append('candidate_jobpostedTitle', jobpost_title);

    axios
      .post(`${global.Unial_BackendURL}/careers/candidate/register`, formData)
      .then((res) => {
        console.log(res);
        // setOpenState(true);
      })
      .catch((error) => {
        console.log(error);
      });
    setNameState('');
    setEmailState('');
    setPhoneState('');
    setCVFile([]);
    setPreference('');
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  },[])
  return (
    <>
      <Helmet>
        <>
          <title>{jobpost_metaTitle}</title>
          <meta name="description" content={jobpost_metaDescription} />
          <meta name="keywords" content={jobpost_metaKeywords} />
        </>
      </Helmet>
      <Navbar />
      <main className="pt-4" style={{ marginTop: "7vh" }}>
        <div className="container">
          <div className="row gy-4">
            <div className="d-grid align-items-center center-on-small">
              <h4
                className="m-0"
                style={{ color: "#7F0000", fontWeight: "700" }}
              >
                INTERNATIONAL OFFICES
              </h4>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="d-grid align-items-center gap-3">
                <div className="d-grid align-items-center center-on-small">
                  <h5 className="m-0 usaColor">USA Office</h5>
                  <p className="m-0 my-2">
                    <b>Address:</b> <br />
                    315 Montgomery Street 10th Floor San Francisco, CA 94104
                  </p>
                  <p className="m-0">
                    <b>Phone:</b>
                  </p>
                  <p className="m-0">
                    +1 628 229 2879{" "}
                    <a
                      className="mx-2 whatsAppcss "
                      target={"_blank"}
                      href={`https://api.whatsapp.com/send?phone=16282292879`}
                    >
                      <i className="fa-brands fa-whatsapp fa-whatsApp"></i>
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="d-grid align-items-center center-on-small">
                <h5 className="m-0 ukColor">UK Office</h5>
                <p className="m-0 my-2">
                  <b>Address:</b> <br />
                  71-75 Shelton Street, Covent Garden, London, United Kingdom,
                  WC2H 9JQ
                </p>
                <p className="m-0">
                  <b>Phone:</b>
                </p>
                <p className="m-0">
                  +44 7477 179922
                  <a
                    className="mx-2 whatsAppcss "
                    target={"_blank"}
                    href={`https://api.whatsapp.com/send?phone=447477179922`}
                  >
                    <i className="fa-brands fa-whatsapp fa-whatsApp"></i>
                  </a>
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="d-grid align-items-center center-on-small">
                <h5 className="m-0 pakColor">Pakistan Office</h5>
                <p className="m-0 my-2">
                  <b>Address:</b> <br />
                  UNIAL Ofiice, Susan Road, Faisalabad Punjab, Pakistan
                </p>
                <p className="m-0">
                  <b>Phone:</b>
                </p>
                <p className="m-0">
                  +92 301 3949480{" "}
                  <a
                    className="mx-2 whatsAppcss "
                    href={`https://api.whatsapp.com/send?phone=923013949480`}
                  >
                    <i className="fa-brands fa-whatsapp fa-whatsApp"></i>
                  </a>
                </p>
              </div>
            </div>
          </div>
          <hr className="my-5" />
          <div className="d-grid align-items-center">
            <form
              method="POST"
              encType="multipart/form-data"
              className="d-grid align-items-center gap-3"
            >
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Name <span style={{ color: "#7F0000" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      value={nameState}
                      onChange={(e) => setNameState(e.target.value)}
                      name="name"
                      id="name"
                      placeholder="Your Name"
                      className="form-control form-control-lg fs-6"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="email" className="form-label">
                      Email <span style={{ color: "#7F0000" }}>*</span>
                    </label>
                    <input
                      type={"email"}
                      value={emailState}
                      onChange={(e) => setEmailState(e.target.value)}
                      name="email"
                      id="email"
                      placeholder="Your Working Email"
                      className="form-control form-control-lg fs-6"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label htmlFor="phone" className="form-label">
                      Phone <span style={{ color: "#7F0000" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      value={phoneState}
                      onChange={(e) => setPhoneState(e.target.value)}
                      name="phone"
                      id="phone"
                      placeholder="Your Phone Numer"
                      className="form-control form-control-lg fs-6"
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Upload CV <span style={{ color: "#7F0000" }}>*</span>
                    </label>
                    <input
                      type={"file"}
                      onChange={(e) => {
                        console.log(e.target.files);
                        setCVFile(e.target.files[0]);
                      }}
                      name="cv"
                      id="cv"
                      placeholder="Drop Your CV"
                      className="form-control form-control-lg fs-6"
                      required
                    />
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Job Type <span style={{ color: "#7F0000" }}>*</span>
                    </label>
                    <select
                      className="form-select form-control-lg"
                      value={preference}
                      onChange={(e) => setPreference(e.target.value)}
                    >
                      <option value="">Your Preference</option>
                      <option value="OnSite">Onsite</option>
                      <option value="Remote">Remote</option>
                      <option value="Both">Both Feasible</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <button
                    type="submit"
                    onClick={handleSubmit}
                    className="footerupperSectionMainBtn active-button"
                  >
                    Get A Quote
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

export default JobPostPage;

import React from "react";
import "./Utils.css";
import { ColorOptions } from "./Colors";
import { useStateContext } from "../../context/ThemeContext";
import { useRef } from "react";

const Settings = () => {
  const {
    setColor,
    setMode,
    currentMode,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();
  const useDarkRef = useRef(null);
  const useLightRef = useRef(null);
  // console.log(currentColor, "check");

  const handleDarkRef = () => {
    useDarkRef.current.click();
  };

  const handleLightRef = () => {
    useLightRef.current.click();
  };

  return (
    <>
      {!themeSettings ? (
        <div className="setting-main">
          <button
            type="button"
            onClick={() => setThemeSettings(true)}
            style={{ background: currentColor.color }}
            className="setting-main-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke={"#fff"}
              width={27}
              height={27}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
              />
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
          </button>
        </div>
      ) : (
        <div
          className="setting-sidebar-overlay"
          style={{
            background:
              currentMode === "Dark"
                ? "rgba(0,0,0,0.1)"
                : "rgba(255,255,255,0.1)",
          }}
        >
          <div
            className="settings-sidebar-main"
            style={{
              background:
                currentMode === "Dark"
                  ? "rgba(0,0,0,0.7)"
                  : "rgba(255,255,255,0.9)",
            }}
          >
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <h5
                  className={`${
                    currentMode === "Dark" ? "text-white" : "text-black"
                  } m-0`}
                >
                  Settings
                </h5>
              </div>
              <button
                type="button"
                onClick={() => setThemeSettings(false)}
                className="settings-btn-close m-0"
                style={{ background: currentColor.color }}
              >
                <i className="fas fa-x text-white m-0"></i>
              </button>
            </div>
            <hr className="my-4" />
            <div className="d-grid align-items-center">
              <h5
                className={currentMode === "Dark" ? "text-white" : "text-black"}
              >
                Theme Options
              </h5>
              <div className="d-grid align-items-center gap-3 mt-3">
                <div
                  className="d-flex align-items-center gap-2"
                  style={{ cursor: "pointer" }}
                  onClick={handleLightRef}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke={currentMode === "Dark" ? "#fff" : "#000"}
                    width={25}
                    height={25}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M12 3v2.25m6.364.386l-1.591 1.591M21 12h-2.25m-.386 6.364l-1.591-1.591M12 18.75V21m-4.773-4.227l-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                    />
                  </svg>
                  <input
                    type="radio"
                    id="light"
                    name="theme"
                    value="Light"
                    hidden
                    ref={useLightRef}
                    onChange={setMode}
                    checked={currentMode === "Light"}
                  />
                  <label
                    htmlFor="light"
                    className={`${
                      currentMode === "Dark" ? "text-white" : "text-black"
                    } m-0 fw-semibold`}
                    style={{cursor: 'pointer'}}
                  >
                    Light
                  </label>
                </div>
                <div
                  className="d-flex align-items-center gap-2"
                  style={{ cursor: "pointer" }}
                  onClick={handleDarkRef}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={2}
                    stroke={currentMode === "Dark" ? "#fff" : "#000"}
                    width={23}
                    height={23}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M21.752 15.002A9.718 9.718 0 0118 15.75c-5.385 0-9.75-4.365-9.75-9.75 0-1.33.266-2.597.748-3.752A9.753 9.753 0 003 11.25C3 16.635 7.365 21 12.75 21a9.753 9.753 0 009.002-5.998z"
                    />
                  </svg>
                  <input
                    type="radio"
                    id="dark"
                    name="theme"
                    value="Dark"
                    ref={useDarkRef}
                    hidden
                    className="cursor-pointer"
                    onChange={setMode}
                    checked={currentMode === "Dark"}
                  />
                  <label
                    htmlFor="dark"
                    className={`${
                      currentMode === "Dark" ? "text-white" : "text-black"
                    } m-0 fw-semibold`}
                    style={{cursor: 'pointer'}}
                  >
                    Dark
                  </label>
                </div>
              </div>
            </div>
            <hr className="my-4" />
            <div className="d-grid align-items-center">
              <h5
                className={currentMode === "Dark" ? "text-white" : "text-black"}
              >
                Theme Colors
              </h5>
              <div className="d-flex flex-wrap align-items-center justify-content-between gap-2">
                {ColorOptions?.map((c, i) => (
                  <div
                    className="d-flex align-items-center flex-column justify-content-center gap-2"
                    key={i}
                  >
                    <button
                      type="button"
                      className="settings-color-button"
                      style={{ background: c.color }}
                      onClick={() => {
                        setColor(c);
                        setThemeSettings(false);
                      }}
                    >
                      <i
                        className={`fa fa-check text-white m-0 ${
                          c.color === currentColor.color ? "d-block" : "d-none"
                        }`}
                      ></i>
                    </button>
                    <h6
                      className={`text-capitalize ${
                        currentMode === "Dark" ? "text-white" : "text-black"
                      } fs-6 fst-normal`}
                    >
                      {c.name}
                    </h6>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Settings;

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./HeaderText.css";
import "../../GlobalVariables.js";
import { useStateContext } from "../../context/ThemeContext";

const HeaderText = () => {
  const [dataState, setDataState] = useState([]);
  const { currentMode, currentColor } = useStateContext();

  useEffect(() => {
    axios
      .get(`${global.Unial_BackendURL}/herosection`)
      .then((res) => setDataState(res.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <>
      {dataState.length > 0 ? (
        <div className="container homebannercontent">
          <div className="row justify-content-md-center">
            {dataState?.map((val, i) => (
              <div
                key={i}
                className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
              >
                <h1
                  className={`mainTextHeader ${
                    currentMode === "Dark"
                      ? "text-gradient-dark"
                      : "text-gradient-white"
                  }`}
                  style={{background: currentColor.color}}
                >
                  {val.herosection_title}
                </h1>
                <h6
                  className={
                    currentMode === "Dark" ? "text-white" : "text-dark"
                  }
                >
                  {val.herosection_text}
                </h6>
                <Link to={`/${val.herosection_url}`}>
                  <button
                    type="button"
                    className="subTextHeader"
                    style={{
                      background: currentColor.color,
                      border: `1px solid ${currentColor.color}`,
                    }}
                  >
                    {val.herosection_button}{" "}
                    <i className="fa-solid fa-arrow-right-long subTextHeaderIcon"></i>
                  </button>
                </Link>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default HeaderText;

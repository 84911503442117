import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import "./Blog.css";
import Navbar from "../../components/Header/Navbar/Navbar";
import Footer from "../Footer/Footer";
import axios from "axios";
import "../../GlobalVariables.js";
import Post from "./post/Post";
import BlogNavbar from "./BlogNavbar";
import BottomNavBlog from "./BlogNavbar/BottomNavBlog";
import { useStateContext } from "../../context/ThemeContext";

const Blogs = () => {
  const [blogdata, setBlogData] = useState([]);
  const [categoryState, setCategoryState] = useState([]);
  const { currentMode } = useStateContext();

  useEffect(() => {
    const request1 = axios.get(`${global.Unial_BackendURL}/blog/category`);
    const request2 = axios.get(`${global.Unial_BackendURL}/blogsection`);

    Promise.all([request1, request2])
      .then((responses) => {
        const category = responses[0].data;
        const blog = responses[1].data;

        // Create an empty object to store the filtered blogs by category
        const filterBlogs = {};

        // Loop through the categories and filter the blogs for each category
        category.forEach((category) => {
          filterBlogs[category.categorysection_id] = blog?.filter(
            (blog) => blog?.blog_category_id === category.categorysection_id
          );
        });

        // Set the state with the categories and filtered portfolios
        setCategoryState(category);
        setBlogData(filterBlogs);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>Blogs - Unial Solutions</title>
        <meta property="og:title" content={"Unial Solutions Blog"} />
        <meta
          property="og:description"
          content={"UNIAL 360 Software Consulting and Development - Blogs"}
        />
        <meta
          name="description"
          content={
            "UNIAL 360 Software Consulting and Development - Blogs, React.js Next.js Blockchain"
          }
        />
        <meta
          name="keywords"
          content={
            "Unial Blogs, React.js, Next.js React Crash Course, Next.js Crash Course, Blockchain, Smart Contracts"
          }
        />
      </Helmet>
      <Navbar />
      <BlogNavbar />
      <main>
        {categoryState?.map((cat, i) => (
          <div
            key={i}
            className="d-flex flex-column w-auto h-auto"
            id={`${cat?.categorysection_id}-portfolios`}
          >
            <div className="blog-hero">
              <div
                className="blog-hero-content"
                style={{
                  background: `${currentMode === "Dark" ? "#000" : "#f2f2f2"}`,
                  boxShadow: `${
                    currentMode === "Dark"
                      ? `0 0 0.1rem rgba(255,255,255, 1)`
                      : `0 0 0.1rem rgba(0,0,0, 0.1)`
                  }`,
                }}
              >
                <p
                  className={
                    currentMode === "Dark" ? "text-white" : "text-black"
                  }
                >
                  Become a member and stay up to
                </p>
                <p
                  className={
                    currentMode === "Dark" ? "text-white" : "text-black"
                  }
                >
                  date with our favorite topics and publications.
                </p>
                <h1 className={`mt-3 ${
                    currentMode === "Dark" ? "text-white" : "text-black"
                  }`}>
                  <span style={{ color: "#dc2828" }}>
                    {cat?.categorysection_name}
                  </span>
                </h1>
                <h1 className={`mb-5 ${
                    currentMode === "Dark" ? "text-white" : "text-black"
                  }`}>
                  <span style={{ color: "#dc2828" }}>made</span> just for you.
                </h1>
              </div>
              <div className="blog-hero-posts">
                <div className="container-fluid">
                  <div className="row">
                    {blogdata[cat?.categorysection_id]
                      ?.slice(0, 2)
                      ?.map((item, i) => (
                        <div
                          key={i}
                          className="col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <Link
                            to={`/blogs/${item.blogsection_slug}`}
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            <Post
                              img={`${global.Unial_BackendURL}/nodeassets/${item.blogsection_image}`}
                              title={item.blogsection_title}
                              currentMode={currentMode}
                            />
                          </Link>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <section className="my-5">
              <div className="container-fluid">
                <div className="row gy-4">
                  {blogdata[cat?.categorysection_id]
                    ?.slice(2)
                    ?.map((item, i) => (
                      <div
                        key={i}
                        className="col-xl-3 col-lg-4 col-md-6 col-sm-12 col-12"
                      >
                        <Link
                          to={`/blogs/${item.blogsection_slug}`}
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          <Post
                            img={`${global.Unial_BackendURL}/nodeassets/${item.blogsection_image}`}
                            title={item.blogsection_title}
                            currentMode={currentMode}
                          />
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </section>
          </div>
        ))}
      </main>
      <section className="send-mail">
        <div className="container mail-container">
          <div className="d-grid align-items-center">
            <h5 className="text-white">Contact</h5>
            <hr style={{ width: "100%", border: "1px solid #fff" }} />
            <h1 className="text-white">
              Quieres saber más sobre <br /> nosotros?
            </h1>
            <button type="button" className="blog-button">
              Send An Email <i className="fas fa-arrow-right"></i>
            </button>
          </div>
          <div className="d-grid align-items-center text-light">
            <h5 className="m-0">Phone</h5>
            <p>Ph: +3.431.705.5448</p>
            <h5 className="m-0">Email</h5>
            <p>hola@colabriothemes.com</p>
            <h5 className="m-0">Direction</h5>
            <p>Gran Via de les Corts</p>
            <p>Catalanes, 461,</p>
            <p>08015 Barcelona</p>
          </div>
        </div>
      </section>
      <section className="bg-black let-talk">
        <div className="container">
          <div className="let-talk-container">
            <div className="d-grid align-items-center">
              <h5 className="text-light">COLLABORATION</h5>
              <h1 className="text-white">Let's Talk</h1>
            </div>
            <div className="d-grid align-items-end">
              <button type="button" className="blog-button">
                Send An Email <i className="fas fa-arrow-right"></i>
              </button>
            </div>
          </div>
          <hr style={{ border: "1px solid #fff" }} />
          <p style={{ color: "#ffffff" }}>
            We’re <b>a team of creatives</b> who are excited about unique ideas
            and help fin-tech
            <br /> companies to <b>create amazing identity</b> by crafting
            top-notch UI/UX.
          </p>
        </div>
      </section>
      <BottomNavBlog />
      <Footer />
    </>
  );
};

export default Blogs;

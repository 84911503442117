import React from 'react'
import "./Navbar.css";
import { Link } from 'react-scroll';

const BottomNavbar = ({ currentMode }) => {
    return (
        <div className={`aiz-mobile-bottom-nav d-xl-none fixed-bottom shadow-lg ${currentMode === "Dark" ? "bg-black" : "bg-white border-dark border-top"}`}
        // className={currentMode === "Dark" ? "nav-link nav-link-white" : "nav-link nav-link-dark"}
        >
            <div className="d-flex justify-content-around align-items-center">
                <Link to='ourServices' spy={true} smooth={true} offset={-100} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right,'bg-soft-primary')}}">
                    <span className="d-inline-block position-relative px-2">
                        {/* <i className="fas fa-home"></i> */}
                        <i className={`fa fa-home ${currentMode === "Dark" ? "text-white" : "text-black"}`}></i>
                        <p className={`small ${currentMode === "Dark" ? "text-white" : "text-black"}`}>Our Services</p>
                    </span>
                </Link>
                <Link to='appdevelopes' spy={true} smooth={true} offset={-100} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right">
                    <span className="d-inline-block position-relative px-2">
                    <i className={`fas fa-receipt ${currentMode === "Dark" ? "text-white" : "text-black"}`}></i>
                        <p className={`small ${currentMode === "Dark" ? "text-white" : "text-black"}`}>We Develop</p>
                    </span>
                </Link>
                <Link to='portfolio' spy={true} smooth={true} offset={-110} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right">
                    <span className="d-inline-block position-relative px-2">
                    <i className={`fas fa-boxes ${currentMode === "Dark" ? "text-white" : "text-black"}`}></i>
                        <p className={`small ${currentMode === "Dark" ? "text-white" : "text-black"}`}>Our Portfolio</p>
                    </span>
                </Link>
            </div>
        </div>
    )
}

export default BottomNavbar;
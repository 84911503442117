import React from "react";
import "../services/SubServiceNav/SubServiceNav.css";
import { Link } from "react-scroll";

const MainServiceDoubleNav = () => {
  return (
    <div className="container mobile-service-double d-xl-block d-none">
      <div className="row">
        <div className="col d-flex justify-content-start align-items-center">
          <Link
            to="servicehire"
            activeClass="navItemActive"
            spy={true}
            offset={-200}
            duration={500}
            className="doubleNavItems"
          >
            Hire
          </Link>
          <Link
            to="awards"
            activeClass="navItemActive"
            spy={true}
            offset={-200}
            duration={500}
            className="doubleNavItems"
          >
            Awards
          </Link>
          <Link
            to="servicedevelop"
            activeClass="navItemActive"
            spy={true}
            offset={-200}
            duration={500}
            className="doubleNavItems"
          >
            We Develop
          </Link>
          <Link
            to="servicetechnologies"
            activeClass="navItemActive"
            spy={true}
            offset={-200}
            duration={500}
            className="doubleNavItems"
          >
            Technologies
          </Link>
          <Link
            to="serviceportfolio"
            activeClass="navItemActive"
            spy={true}
            offset={-200}
            duration={500}
            className="doubleNavItems"
          >
            Portfolio
          </Link>
          <Link
            to="servicecooperation"
            activeClass="navItemActive"
            spy={true}
            offset={-200}
            duration={500}
            className="doubleNavItems"
          >
            Cooperation Model
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MainServiceDoubleNav;

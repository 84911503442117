import axios from "axios";
import React, { useEffect, useState } from "react";
import Navbar from "../../components/Header/Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./ContactUs.css";
import SuccessPage from "../success/SuccessPage";
import { Helmet } from "react-helmet-async";
import ContactBottomNav from "./ContactBottomNav";
import { useStateContext } from "../../context/ThemeContext";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [nameState, setNameState] = useState("");
  const [companyState, setCompanyState] = useState("");
  const [emailState, setEmailState] = useState("");
  const [phoneState, setPhoneState] = useState("");
  const [subjectState, setSubjectState] = useState("");
  const [budgetState, setBudgetState] = useState("");
  const [details, setDetails] = useState("");
  const [openState, setOpenState] = useState("");
  const { currentMode } = useStateContext();

  const [nameError, setnameError] = useState('')
  const [companyError, setcompanyError] = useState('')
  const [emailError, setemailError] = useState('')
  const [phoneError, setphoneError] = useState('')
  const [subjectError, setsubjectError] = useState('')
  const [projectDetailError, setprojectDetailError] = useState('')

  const [captacha, setcaptacha] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!nameState) { return setnameError("Please enter the name") } else { setnameError("") }
    if (!companyState) { return setcompanyError("Please enter the company name") } else { setcompanyError("") }
    if (!emailState) { return setemailError("Please enter the email") } else { setemailError("") }
    if (!phoneState) { return setphoneError("Please enter the phone number") } else { setphoneError("") }
    if (!subjectState) { return setsubjectError("Please enter the subject") } else { setsubjectError("") }
    if (!details) { return setprojectDetailError("Please enter the project details") } else { setprojectDetailError("") }
    if (!captacha)
      return

    const slackData = {
      text: `Name: ${nameState} \nCompany: ${companyState} \nEmail: ${emailState} \nPhone: ${phoneState} \nSubject: ${subjectState} \nBudget: ${budgetState} \nProject: ${details}`,
    };

    const DiscordData = {
      content: "Message Recived",
      tts: false,
      color: "white",
      embeds: [
        {
          title: nameState,
          description: `Company: ${companyState} \nEmail: ${emailState} \nPhone: ${phoneState} \nSubject: ${subjectState} \nBudget: ${budgetState} \nProject: ${details}`,
        },
      ],
    };

    axios
      .post(
        `https://discord.com/api/webhooks/1088369608068694076/19V7eTrf3vtmcfWWGeV8y79u5rBfr6fRZtVxeuCxnJEYOHNKOYzK15sMzkrh5jcBTVbQ`,
        DiscordData
      )
      .then((res) => console.log(res.data))
      .catch((error) => console.log(error));

    let slackRes = await axios.post(
      "https://hooks.slack.com/services/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",
      JSON.stringify(slackData),
      {
        withCredentials: false,
        transformRequest: [
          (data) => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
            setOpenState(true)
            return data;
          },
        ],
      }
    );

    axios
      .post(`${global.Unial_BackendURL}/sendEmailUnial`, {
        contactus_name: nameState,
        contactus_company: companyState,
        contactus_email: emailState,
        contactus_phone: phoneState,
        contactus_subject: subjectState,
        contactus_buget: budgetState,
        contactus_details: details,
      })
      .then((res) => {
        // console.log(res);
      })
      .catch((error) => {
        console.log(error);
      });

    if (slackRes.status === 200) {
      setNameState("");
      setCompanyState("");
      setEmailState("");
      setPhoneState("");
      setSubjectState("");
      setBudgetState("");
      setDetails("");
      setcaptacha("")
      // setOpenState("");
    } else {
      alert("Error Occurred!");
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <>
          <meta
            name="description"
            content={"Talk To Expert - Unial Solutions"}
          />
          <meta
            name="keywords"
            content={"Talk To Expert - Unial Solutions - Unial - Unial Sol"}
          />
        </>
      </Helmet>
      <Navbar />
      <ContactBottomNav />
      <main className="" style={{ marginTop: "5vh" }}>
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div className="contact-main">
                <div className="d-grid align-items-center center-on-small">
                  <p
                    className={`contact-title`}
                    style={{ fontWeight: "700", fontSize: "65px", color: "black" }}
                  >
                    Contact <span style={{ color: "#1876BE" }}>U</span><span style={{ color: "#29398E" }}>N</span><span style={{ color: "#016835" }}>I</span><span style={{ color: "#BD1F2E" }}>A</span><span style={{ color: "#FBAE41" }}>L</span>
                  </p>
                  <a href="tel:+923013949480" style={{ fontSize: "30px", fontWeight: "700", color: 'black', padding: "5px", textDecoration: "none" }}>(0092) 301 3949480</a>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-12">
              <div class="accordion" id="accordionExample">
                <div class="accordion-item">
                  <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo" style={{ padding: "5px" }}>
                      <p className="contact-btn-title" style={{ fontSize: "30px", fontWeight: "700", color: 'black', margin: "0px" }}>  Book meeting with UNIAL </p>
                    </button>
                  </h2>
                  <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                      <iframe
                        src="https://calendly.com/unial/innovatewithunial"
                        height="300"
                        width="100%"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-5" />
          <div className="row gy-4">
            <div className="d-grid align-items-center center-on-small">
              <h4
                className={`m-0`}
                style={{ color: "#7F0000", fontWeight: "700" }}
              >
                INTERNATIONAL OFFICES
              </h4>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="d-grid align-items-center gap-3">
                <div className="d-grid align-items-center center-on-small">
                  <h5 className="m-0 usaColor">USA Office</h5>
                  <p
                    className={`m-0 my-2 ${currentMode === "Dark" ? "text-white" : "text-black"
                      }`}
                  >
                    <b
                      className={
                        currentMode === "Dark" ? "text-white" : "text-black"
                      }
                    >
                      Address:
                    </b>{" "}
                    <br />
                    315 Montgomery Street 10th Floor San Francisco, CA 94104
                  </p>
                  {/* <p
                    className={`m-0 ${currentMode === "Dark" ? "text-white" : "text-black"
                      }`}
                  >
                    <b
                      className={
                        currentMode === "Dark" ? "text-white" : "text-black"
                      }
                    >
                      Phone:
                    </b>
                  </p>
                  <p
                    className={`m-0 ${currentMode === "Dark" ? "text-white" : "text-black"
                      }`}
                  >
                    +1 628 229 2879{" "}
                    <a
                      className="mx-2 whatsAppcss "
                      target={"_blank"}
                      href={`https://api.whatsapp.com/send?phone=16282292879`}
                    >
                      <i className="fa-brands fa-whatsapp fa-whatsApp"></i>
                    </a>
                  </p> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="d-grid align-items-center center-on-small">
                <h5 className="m-0 ukColor">UK Office</h5>
                <p
                  className={`m-0 my-2 ${currentMode === "Dark" ? "text-white" : "text-black"
                    }`}
                >
                  <b
                    className={
                      currentMode === "Dark" ? "text-white" : "text-black"
                    }
                  >
                    Address:
                  </b>{" "}
                  <br />
                  71-75 Shelton Street, Covent Garden, London, United Kingdom,
                  WC2H 9JQ
                </p>
                {/* <p
                  className={`m-0 ${currentMode === "Dark" ? "text-white" : "text-black"
                    }`}
                >
                  <b
                    className={
                      currentMode === "Dark" ? "text-white" : "text-black"
                    }
                  >
                    Phone:
                  </b>
                </p>
                <p
                  className={`m-0 ${currentMode === "Dark" ? "text-white" : "text-black"
                    }`}
                >
                  +44 7477 179922
                  <a
                    className="mx-2 whatsAppcss "
                    target={"_blank"}
                    // href="https://wa.me/+447477179922"
                    href={`https://api.whatsapp.com/send?phone=447477179922`}
                  >
                    <i className="fa-brands fa-whatsapp fa-whatsApp"></i>
                  </a>
                </p> */}
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <div className="d-grid align-items-center center-on-small">
                <h5 className="m-0 pakColor">Pakistan Office</h5>
                <p
                  className={`m-0 my-2 ${currentMode === "Dark" ? "text-white" : "text-black"
                    }`}
                >
                  <b
                    className={
                      currentMode === "Dark" ? "text-white" : "text-black"
                    }
                  >
                    Address:
                  </b>{" "}
                  <br />
                  UNIAL Ofiice, Susan Road, Faisalabad Punjab, Pakistan
                </p>
                {/* <p
                  className={`m-0 ${currentMode === "Dark" ? "text-white" : "text-black"
                    }`}
                >
                  <b
                    className={
                      currentMode === "Dark" ? "text-white" : "text-black"
                    }
                  >
                    Phone:
                  </b>
                </p>
                <p
                  className={`m-0 ${currentMode === "Dark" ? "text-white" : "text-black"
                    }`}
                >
                  +92 301 3949480{" "}
                  <a
                    className="mx-2 whatsAppcss "
                    // href="https://wa.me/+923013949480"
                    href={`https://api.whatsapp.com/send?phone=923013949480`}
                  >
                    <i className="fa-brands fa-whatsapp fa-whatsApp"></i>
                  </a>
                </p> */}
              </div>
            </div>
          </div>
          <hr className="my-5" />
          <div className="d-grid align-items-center">
            <form
              method="POST"
              encType="multipart/form-data"
              className="d-grid align-items-center gap-3"
            >
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label
                      htmlFor="name"
                      className={`form-label ${currentMode === "Dark" ? "text-white" : "text-black"
                        }`}
                    >
                      Name <span style={{ color: "#7F0000" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      value={nameState}
                      onChange={(e) => { setNameState(e.target.value); setnameError("") }}
                      name="name"
                      id="name"
                      placeholder="Your Name"
                      className={`form-control ${currentMode === "Dark" ? "input-theme-black" : "input-theme-white"}`}
                      required
                    />
                    <p style={{ color: "#BD1E2D" }}>{nameError}</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label
                      htmlFor="comapny"
                      className={`form-label ${currentMode === "Dark" ? "text-white" : "text-black"
                        }`}
                    >
                      Company <span style={{ color: "#7F0000" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      value={companyState}
                      onChange={(e) => { setCompanyState(e.target.value); setcompanyError("") }}
                      name="company"
                      id="company"
                      placeholder="Your Company Name"
                      className={`form-control ${currentMode === "Dark" ? "input-theme-black" : "input-theme-white"}`}
                      required
                    />
                    <p style={{ color: "#BD1E2D" }}>{companyError}</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label
                      htmlFor="email"
                      className={`form-label ${currentMode === "Dark" ? "text-white" : "text-black"
                        }`}
                    >
                      Email <span style={{ color: "#7F0000" }}>*</span>
                    </label>
                    <input
                      type={"email"}
                      value={emailState}
                      onChange={(e) => { setEmailState(e.target.value); setemailError("") }}
                      name="email"
                      id="email"
                      placeholder="Your Working Email"
                      className={`form-control ${currentMode === "Dark" ? "input-theme-black" : "input-theme-white"}`}
                      required
                    />
                    <p style={{ color: "#BD1E2D" }}>{emailError}</p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="form-group">
                    <label
                      htmlFor="phone"
                      className={`form-label ${currentMode === "Dark" ? "text-white" : "text-black"
                        }`}
                    >
                      Phone <span style={{ color: "#7F0000" }}>*</span>
                    </label>
                    {/* <input
                      type={"text"}
                      value={phoneState}
                      onChange={(e) => setPhoneState(e.target.value)}
                      name="phone"
                      id="phone"
                      placeholder="Your Phone Numer"
                      className={`form-control ${currentMode === "Dark" ? "input-theme-black" : "input-theme-white"}`}
                      required
                    /> */}
                    <PhoneInput
                      country={'us'}
                      className='mb-2'
                      inputStyle={{ height: '31.5px', width: "100%", borderRadius: 7, borderColor: "#DEE2E6", boxShadow: "none" }}
                      value={phoneState}
                      onChange={(e) => { setPhoneState(e); setphoneError("") }}
                    />
                    <p style={{ color: "#BD1E2D" }}>{phoneError}</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label
                      htmlFor="subject"
                      className={`form-label ${currentMode === "Dark" ? "text-white" : "text-black"
                        }`}
                    >
                      Subject <span style={{ color: "#7F0000" }}>*</span>
                    </label>
                    <input
                      type={"text"}
                      value={subjectState}
                      onChange={(e) => { setSubjectState(e.target.value); setsubjectError("") }}
                      name="subject"
                      id="subject"
                      placeholder="Choose a Subject"
                      className={`form-control ${currentMode === "Dark" ? "input-theme-black" : "input-theme-white"}`}
                      required
                    />
                    <p style={{ color: "#BD1E2D" }}>{subjectError}</p>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="form-group">
                    <label
                      htmlFor="budget"
                      className={`form-label ${currentMode === "Dark" ? "text-white" : "text-black"
                        }`}
                    >
                      Choose A Budget {"(USD)"}
                    </label>
                    <input
                      type={"text"}
                      value={budgetState}
                      onChange={(e) => setBudgetState(e.target.value)}
                      name="budget"
                      id="budget"
                      placeholder="Your Budget"
                      className={`form-control ${currentMode === "Dark" ? "input-theme-black" : "input-theme-white"}`}
                      required
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group">
                  <label
                    htmlFor="details"
                    className={`form-label ${currentMode === "Dark" ? "text-white" : "text-black"
                      }`}
                  >
                    Project Details <span style={{ color: "#7F0000" }}>*</span>
                  </label>
                  <textarea
                    rows={"6"}
                    value={details}
                    onChange={(e) => { setDetails(e.target.value); setprojectDetailError("") }}
                    id="details"
                    placeholder="Brief Project Details"
                    className={`form-control ${currentMode === "Dark" ? "textarea-black" : "textarea-white"}`}
                    required
                  />
                  <p style={{ color: "#BD1E2D" }}>{projectDetailError}</p>
                </div>
              </div>
              <div className="row">
                <ReCAPTCHA
                  sitekey='6Le0gOMoAAAAAD6G0ZafQt-N-Vwr3wx3LAdc50fi'
                  onChange={e => setcaptacha(e)}
                />
              </div>
              <div className="row">
                <div className="form-group">
                  {captacha && nameState && companyState && emailState && phoneState && subjectState && details && <button
                    type="submit"
                    onClick={handleSubmit}
                    className="footerupperSectionMainBtn active-button"
                  >
                    Get A Quote
                  </button>}
                </div>
              </div>
            </form>
          </div>
        </div>
      </main>
      {!openState && <Footer />}
      {openState && (
        <div className="overlay-popup">
          <SuccessPage setOpenState={setOpenState} />
        </div>
      )}
    </>
  );
};

export default Contact;

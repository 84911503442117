import React, { useEffect, useState } from "react";
import axios from "axios";
import { useStateContext } from "../../context/ThemeContext";
import { truncate } from "lodash";
import "./Products.css";
import AnimatedButton from "../../pages/Home/Portfolio/AnimatedButton";

const Products = () => {
  const [loading, setLoading] = useState(true);
  const [produtsCategory, setProductsCategory] = useState([]);
  const [productsState, setProductsState] = useState([]);
  const { currentMode, currentColor } = useStateContext();

  const fetchData = () => {
    setLoading(true);
    Promise.all([
      axios.get(`${global.Unial_BackendURL}/productcategory`),
      axios.get(`${global.Unial_BackendURL}/products`),
    ])
      .then((responses) => {
        setProductsCategory(responses[0].data);
        setProductsState(responses[1].data);
        setLoading(false);
      })
      .catch((error) => error);
  };

  // console.log(productsState, "servicesState");

  useEffect(() => {
    fetchData();
  }, []);
  return (
    <>
      {loading ? null : (
        <section className="portfolio-section">
          <div className="container">
            <div className="d-grid align-items-center">
              <h2 className={`a-section__title h2 step-up 1`}>
                <span
                  className={
                    currentMode === "Dark" ? "text-white" : "text-black"
                  }
                >
                  Our Products
                </span>
              </h2>
              <p className="icon-box-editable__description">
                <span
                  className={
                    currentMode === "Dark" ? "text-white" : "text-black"
                  }
                >
                  Ready-to-deploy applications that connect, enhance, and
                  empower.
                </span>
              </p>
            </div>
            <div className="row theme-row mt-3">
              {productsState?.map((val, i) => (
                <div key={i} className="col-lg-4 col-md-6 col-sm-6 col-12">
                  <div className="products-card theme-outline">
                    <div className="products-img">
                      <img
                        src={`${global.Unial_BackendURL}/nodeassets/${val.product_image}`}
                        alt={val.products_id}
                      />
                    </div>
                    <div className="products-text">
                      <h6 className="m-0 products-name">{val.product_name}</h6>
                      <p className="m-0">
                        {truncate(val.product_shorttext, {
                          length: 100,
                        })}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="d-flex align-items-center justify-content-center mt-5">
                <AnimatedButton
                  btnText={"See All Products"}
                  url={"#"}
                />
              </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Products;

import React, { useState, useEffect } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import { EffectCoverflow, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/navigation";
import { truncate } from "lodash";
import "./Portfolio.css";
import AnimatedButton from "./AnimatedButton";
import "../../../GlobalVariables";
import { useNavigate } from "react-router-dom";
import ActionCall from "./ActionCall";
import { useStateContext } from "../../../context/ThemeContext";

const Portfolio = () => {
  // console.log(id, 'id');
  const [porfolioAPI, setPortfoliosAPI] = useState([]);
  const [indexVal, setindexVal] = useState(0);
  const navigate = useNavigate();
  const { currentMode, currentColor } = useStateContext();

  useEffect(() => {
    axios
      .get(`${global.Unial_BackendURL}/portfolios/distinct`)
      .then((res) => {
        setPortfoliosAPI(res?.data);
      })
      .catch((error) => console.log(error));
  }, []);

  const handleSwiperUpdate = (data) => {
    if (data === "increment") {
      setindexVal((indexVal + 1) % porfolioAPI.length);
    } else if (data === "decrement") {
      setindexVal((indexVal + porfolioAPI.length - 1) % porfolioAPI.length);
    }
  };

  return (
    <>
      <section className="portfolio-section">
        {porfolioAPI?.length > 0 ? (
          <div className="container">
            <ActionCall currentMode={currentMode} />
            <div
              className="container d-grid align-items-center portfolio-titles my-5"
              id="portfolio"
            >
              <h1
                className={`text-black m-0 fw-semibold ${
                  currentMode === "Dark" ? "text-white" : "text-black"
                }`}
              >
                Our latest projects
              </h1>
              <hr
                style={{
                  opacity: "1",
                  zIndex: "-1",
                  width: "3rem",
                  margin: "1vh 0 2vh 0",
                  border: "1px solid #ff9500",
                }}
              />
              <p
                className={`m-0 ${
                  currentMode === "Dark" ? "text-white" : "text-black"
                }`}
              >
                A fresh approach to financial services for entrepreneurs
              </p>
            </div>
            <div>
              <Swiper
                navigation
                loop={true}
                effect={"coverflow"}
                slidesPerView={4}
                spaceBetween={0}
                grabCursor={false}
                centeredSlides={true}
                allowTouchMove={false}
                modules={[Navigation, EffectCoverflow]}
                onNavigationNext={() => handleSwiperUpdate("increment")}
                onNavigationPrev={() => handleSwiperUpdate("decrement")}
                coverflowEffect={{
                  rotate: 5,
                  depth: -30,
                  stretch: 0,
                  modifier: 5,
                  slideShadows: false,
                }}
                breakpoints={{
                  280: { centeredSlides: true, slidesPerView: 1 },
                  425: { centeredSlides: true, slidesPerView: 1 },
                  550: { centeredSlides: true, slidesPerView: 1.3 },
                  767: { centeredSlides: true, slidesPerView: 1.5 },
                  991: { centeredSlides: true, slidesPerView: 2 },
                  1200: { centeredSlides: true, slidesPerView: 3 },
                  1400: { centeredSlides: true, slidesPerView: 3 },
                  1600: { centeredSlides: true, slidesPerView: 3 },
                }}
              >
                {porfolioAPI.map((val, sIndex) => (
                  <SwiperSlide key={sIndex} className="mb-5">
                    <div
                      className="portfolio-slides"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        navigate(`/portfolio/${val.portfolios_id}`, {
                          state: { dataObj: val },
                        })
                      }
                    >
                      <div className="portfolio-slider-img d-flex align-items-center">
                        <img
                          src={`${global.Unial_BackendURL}/nodeassets/${val.portfolios_featuredimages}`}
                          alt={`id/${val.portfolios_id}`}
                          className="slider-img"
                        />
                      </div>
                      {indexVal === sIndex ? (
                        <div className="d-grid align-items-center gap-2 portfolio-slider-content">
                          <h5 className="m-0 fs-6 fw-semibold">
                            {val.portfolios_industory}
                          </h5>
                          <h4 className="m-0 content-box-title">
                            {val.portfolios_title}
                          </h4>
                          <p className="m-0">
                            {truncate(val.portfolios_shortdesc, {
                              length: 100,
                            })}
                          </p>
                        </div>
                      ) : null}
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className="d-flex align-items-center justify-content-center">
                <AnimatedButton
                  btnText={"See All Stories"}
                  url={"portfolios"}
                />
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </>
  );
};

export default Portfolio;

import React from 'react'
// import image1 from "../../../assets/block.png";
import { Link } from 'react-router-dom';

const SubSectionComp = ({ imgSrc, sId, slug }) => {
    // {`/subservices/${sId}`}
    return (
    <>
        <Link to={`/subservices/${slug}`}>
            <img src={`${global.Unial_BackendURL}/nodeassets/${imgSrc}`} className="img-thumbnail imgStyle" alt={`service-${sId}`} height="100px" width="120px" />
        </Link>
    </>
    )
}

export default SubSectionComp;
import React from "react";
import "./Dropdown.css";
import { Link } from "react-router-dom";
const DropDownComp = ({ name, imgSrc, sId, link, currentMode }) => {
  // {`/subservices/${sId}`}
  // console.log('serviceId',sId)
  return (
    <>
      <Link className="link-dropdown" to={link}>
        <div className="services_box_items d-grid align-items-center">
          <img
            src={`${global.Unial_BackendURL}/nodeassets/${imgSrc}`}
            className="img-thumbnail shadow-sm mb-1"
            alt="image1"
            height="100px"
            width="100px"
          />
          <p className={`service-text ${currentMode === "Dark" ? "text-white" : "text-dark"}`}>{name}</p>
        </div>
      </Link>
    </>
  );
};

export default DropDownComp;

import React from "react";

const Story = ({ img, title, currentMode }) => {
  return (
    <>
      <div className="unial-post">
        <div className="d-flex align-items-center unial-post-img">
          <img src={img} alt={"img/post"} />
        </div>
        <div className="unial-post-body">
          <h1 className={currentMode === "Dark" ? "text-white" : "text-black"}>
            {title}
          </h1>
        </div>
      </div>
    </>
  );
};

export default Story;

import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../../../components/Header/Navbar/Navbar";
import Footer from "../../../Footer/Footer";
import StoryDoubleNavbar from "../DoubleNavbar/StoryDoubleNavbar";
import "./Stories.css";
import Story from "./Story";
import { useStateContext } from "../../../../context/ThemeContext";

const Stories = () => {
  const [categoryState, setCategoryState] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  const navigate = useNavigate();
  const { currentMode } = useStateContext();

  // console.log(categoryState)
  // console.log(portfolios)

  useEffect(() => {
    const request1 = axios.get(`${global.Unial_BackendURL}/service/category`);
    const request2 = axios.get(
      `${global.Unial_BackendURL}/portfolios/distinct`
    );

    Promise.all([request1, request2])
      .then((responses) => {
        const category = responses[0].data;
        const portfolio = responses[1].data;

        // Create an empty object to store the filtered portfolios by category
        const filteredPortfolios = {};

        // Loop through the categories and filter the portfolios for each category
        category.forEach((category) => {
          filteredPortfolios[category.category_id] = portfolio.filter(
            (portfolio) =>
              portfolio.portfolios_services_id === category.category_id
          );
        });

        // Set the state with the categories and filtered portfolios
        setCategoryState(category);
        setPortfolios(filteredPortfolios);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <StoryDoubleNavbar />
      <main style={{ marginTop: "7vh" }}>
        {categoryState?.map((category, i) => (
          <div
            key={i}
            className="d-flex flex-column w-auto h-auto"
            id={`${category.category_id}-portfolios`}
          >
            <div className="stories-hero">
              <div
                className="stories-hero-content"
                style={{
                  background: `${currentMode === "Dark" ? "#000" : "#f2f2f2"}`,
                  boxShadow: `${currentMode === "Dark" ? `0 0 0.1rem rgba(255,255,255, 1)` : `0 0 0.1rem rgba(0,0,0, 0.1)`}`,
                }}
              >
                <p
                  className={
                    currentMode === "Dark" ? "text-white" : "text-black"
                  }
                >
                  Become a member and stay up to
                </p>
                <p
                  className={
                    currentMode === "Dark" ? "text-white" : "text-black"
                  }
                >
                  date with our favorite topics and publications.
                </p>
                <h1
                  className={`mt-3 ${
                    currentMode === "Dark" ? "text-white" : "text-black"
                  }`}
                >
                  {category.category_name}
                </h1>
                <h1
                  className={`mb-5 ${
                    currentMode === "Dark" ? "text-white" : "text-black"
                  }`}
                >
                  <span style={{ color: "#dc2828" }}>made</span> just for you.
                </h1>
              </div>
              <div className="blog-hero-posts">
                <div className="container-fluid">
                  <div className="row">
                    {portfolios[category?.category_id]
                      ?.slice(0, 2)
                      ?.map((portfolio, i) => (
                        <div
                          key={i}
                          onClick={() =>
                            navigate(
                              `/portfolio/${portfolio.portfolios_title}`,
                              {
                                state: { dataObj: portfolio },
                              }
                            )
                          }
                          className="col-lg-6 col-md-6 col-sm-12 col-12"
                        >
                          <Story
                            img={`${global.Unial_BackendURL}/nodeassets/${portfolio.portfolios_featuredimages}`}
                            title={portfolio.portfolios_title}
                            currentMode={currentMode}
                          />
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <section className="mt-4 mb-3">
              <div className="container-fluid">
                <div className="row">
                  {portfolios[category?.category_id]
                    ?.slice(2)
                    ?.map((portfolio, i) => (
                      <div
                        key={i}
                        onClick={() =>
                          navigate(`/portfolio/${portfolio.portfolios_title}`, {
                            state: { dataObj: portfolio },
                          })
                        }
                        className="col-lg-3 col-md-6 col-sm-12 col-12"
                      >
                        <Story
                          img={`${global.Unial_BackendURL}/nodeassets/${portfolio.portfolios_featuredimages}`}
                          title={portfolio.portfolios_title}
                          currentMode={currentMode}
                        />
                      </div>
                    ))}
                </div>
              </div>
            </section>
          </div>
        ))}
      </main>
      <Footer />
    </>
  );
};

export default Stories;

import { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";

import HeaderText from "./pages/Home/HeaderText";
import Navbar from "./components/Header/Navbar/Navbar";
import Footer from "./pages/Footer/Footer";
import DoubleNavbar from "./components/DoubleNavbar/DoubleNavbar";
import Portfolio from "./pages/Home/Portfolio/Portfolio";
import AppCollapse from "./pages/Home/AppCollapse/AppCollapse";
import Certificaitons from "./pages/Home/Certificaitons";
import BlogPosts from "./pages/Home/BlogPosts";
import ServicesSection from "./pages/Home/ServicesSection/ServicesSection";
import Products from "./components/Products/Products";
import ServicesV1 from "./pages/Home/ServicesSection/servicesv1/ServicesV1";

function App() {
  const [metaData, setMetaData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get(`${global.Unial_BackendURL}/seo/homepage`)
      .then((res) => {
        setMetaData(res.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="App">
      <Helmet>
        <title>{metaData[0]?.page_title}</title>
        <meta
          name="keywords"
          content={
            metaData?.length > 0
              ? JSON.parse(metaData[0]?.meta_keywords)?.join(", ")
              : null
          }
        />
        <meta name="desciption" content={metaData[0]?.page_description} />
        <meta name="robots" content="index,follow" />
        <meta name="application-name" content="Unial Solutions" />
        <link rel="author" href={`${global.WebsiteUrl}`} />
        <meta name="generator" content="Unial Solutions" />
        <meta name="creator" content="Unial Solutions" />
        <meta name="publisher" content="Unial Solutions" />
        <meta
          name="googlebot"
          content="max-video-preview:-1, max-image-preview:large, max-snippet:-1"
        />
        <meta property="og:title" content={metaData[0]?.meta_title} />
        <meta
          name="twitter:description"
          content={metaData[0]?.meta_description}
        />
        <meta
          property="og:description"
          content={metaData[0]?.meta_description}
        />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://unialsolutions.com" />
        <script
          type="text/javascript"
          src="https://assets.calendly.com/assets/external/widget.js"
          async
        ></script>
      </Helmet>
      <Navbar />
      <DoubleNavbar />
      <HeaderText style={{ marginBottom: "30%" }} />
      <ServicesSection />
      <ServicesV1 />
      <AppCollapse />
      <Products />
      <Portfolio />
      <Certificaitons />
      <BlogPosts />
      <Footer />
    </div>
  );
}

export default App;

import React from 'react';
import { useParams } from 'react-router-dom';
import BlockchainPage from './BlockchainPage';
import WebAppPage from './WebAppPage';
import MobileAppsPage from './MobileAppsPage';
import MetaAppPage from './MetaAppPage';

const MainServices = () => {
  const { slug } = useParams();
  // console.log(`slug`, slug);

  /**
   * This is hard coded.
   * According to conditions.
  */

  const renderPage = () => {
    switch (slug) {
      case slug:
        return <BlockchainPage />;
      case slug:
        return <WebAppPage />;
      case slug:
        return <MobileAppsPage />;
      case slug:
        return <MetaAppPage />;
      default:
        return <NotFoundPage />;
    }
  };

  return <>{renderPage()}</>;
};

const NotFoundPage = () => {
  return <h1>Page not found</h1>;
};

export default MainServices;
import React, { useState } from "react";
import "./Navbar.css";
import Logo from "../../../assets/logo.png";
import { NavLink } from "react-router-dom";
import Dropdown from "./Dropdown";
import Dropdown2 from "./Dropdown2";
import BottomNavbar from "./BottomNavbar";
import { useStateContext } from "../../../context/ThemeContext";
const Navbar = () => {
  const [dropdownState, setDropdownState] = useState(false);
  const { currentMode } = useStateContext();

  return (
    <>
      <header className={"top-header"}>
        <nav
          className={`${currentMode === "Dark" ? "navbar navbar-expand-lg navbar-dark bg-dark-mode shadow-dark-mode-opacity" : "navbar navbar-expand-lg bg-white-mode"}`}
          style={{ zIndex: "100" }}
        >
          <div className="container">
            <NavLink to={`/`} className="navbar-brand">
              <img src={Logo} alt="logo" height={"55px"} />
            </NavLink>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="offcanvas"
              data-bs-target="#offcanvasScrolling"
              aria-controls="offcanvasScrolling"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`offcanvas offcanvas-start ${
                currentMode === "Dark" ? "bg-black" : "bg-white"
              }`}
              data-bs-scroll="true"
              data-bs-backdrop="false"
              tabIndex="-1"
              id="offcanvasScrolling"
              aria-labelledby="offcanvasScrollingLabel"
            >
              <div className="offcanvas-header">
                <img src={Logo} alt="Logo" height={"40px"} />
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                >
                  {currentMode === "Dark" ? (
                    <i className="fa fa-x text-white fs-3 opacity-100"></i>
                  ) : null}
                </button>
              </div>
              <div
                className={`offcanvas-body text__right ${
                  currentMode === "Dark" ? "bg-black" : "bg-white"
                }`}
              >
                <ul className="navbar-nav ms-auto">
                  <li className="nav-item">
                    <NavLink
                      to={"/"}
                      className={
                        currentMode === "Dark"
                          ? "nav-link nav-link-white"
                          : "nav-link nav-link-dark"
                      }
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item dropdown dropdown-mega position-static">
                    <NavLink
                      className={
                        currentMode === "Dark"
                          ? "nav-link nav-link-white"
                          : "nav-link nav-link-dark"
                      }
                      to={"#"}
                      id="offcanvasNavbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                    >
                      <div className="d-flex">
                        Services{" "}
                        <i className="fa-solid fa-arrow-right-long subTextHeaderIcon ArrowIconNav"></i>
                      </div>
                    </NavLink>
                    <div
                      className={`dropdown-menu ${
                        currentMode === "Dark"
                          ? "bg-dark-mode shadow-dark-mode"
                          : "bg-dark-white"
                      } shadow`}
                      aria-labelledby="offcanvasNavbarDropdown"
                    >
                      <Dropdown currentMode={currentMode} />
                    </div>
                  </li>
                  <li className="nav-item dropdown dropdown-mega position-static">
                    <NavLink
                      className={
                        currentMode === "Dark"
                          ? "nav-link nav-link-white"
                          : "nav-link nav-link-dark"
                      }
                      to={"#"}
                      id="offcanvasNavbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                      data-bs-auto-close="outside"
                      onClick={() => setDropdownState(true)}
                    >
                      <div className="d-flex">
                        Application{" "}
                        <i className="fa-solid fa-arrow-right-long subTextHeaderIcon ArrowIconNav"></i>
                      </div>
                    </NavLink>
                    <div
                      className={`dropdown-menu ${
                        currentMode === "Dark"
                          ? "bg-dark-mode shadow-dark-mode"
                          : "bg-dark-white"
                      } shadow`}
                      aria-labelledby="offcanvasNavbarDropdown"
                    >
                      <Dropdown2 currentMode={currentMode} />
                    </div>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={"/portfolios"}
                      className={
                        currentMode === "Dark"
                          ? "nav-link nav-link-white"
                          : "nav-link nav-link-dark"
                      }
                    >
                      Portfolios
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={"/careers"}
                      className={
                        currentMode === "Dark"
                          ? "nav-link nav-link-white"
                          : "nav-link nav-link-dark"
                      }
                    >
                      Careers
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={"/blogs"}
                      className={
                        currentMode === "Dark"
                          ? "nav-link nav-link-white"
                          : "nav-link nav-link-dark"
                      }
                    >
                      Blogs
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to={"/contactus"}
                      className={
                        currentMode === "Dark"
                          ? "nav-link nav-link-white"
                          : "nav-link nav-link-dark"
                      }
                    >
                      Contact Us
                    </NavLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
      <BottomNavbar currentMode={currentMode} />
    </>
  );
};

export default Navbar;

import React from 'react'
import { Link } from 'react-scroll';
import "../../../../../components/Header/Navbar/Navbar.css";

const BottomNavbar = () => {
    return (
        <div className="aiz-mobile-bottom-nav d-xl-none fixed-bottom bg-white shadow-lg border-top">
            <div className="d-flex justify-content-around align-items-center">
                <Link to='servicehire' spy={true} smooth={true} offset={-100} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right,'bg-soft-primary')}}">
                    <span className="d-inline-block position-relative px-2">
                        <i class="fa-brands fa-hire-a-helper"></i>
                        <p className="small">Hire</p>
                    </span>
                </Link>
                <Link to='serviceawards' spy={true} smooth={true} offset={-100} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right">
                    <span className="d-inline-block position-relative px-2">
                    <i class="fa-solid fa-award"></i>
                        <p className="small">Awards</p>
                    </span>
                </Link>
                <Link to='servicedevelop' spy={true} smooth={true} offset={-110} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right">
                    <span className="d-inline-block position-relative px-2">
                    <i class="fa-solid fa-code"></i>
                        <p className="small">We Develop</p>
                    </span>
                </Link>
                <Link to='serviceportfolio' spy={true} smooth={true} offset={-110} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right">
                    <span className="d-inline-block position-relative px-2">
                    <i className="fas fa-boxes"></i>
                        <p className="small">Portfolio</p>
                    </span>
                </Link>
                <Link to='servicecooperation' spy={true} smooth={true} offset={-110} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right">
                    <span className="d-inline-block position-relative px-2">
                    <i class="fa-solid fa-microchip"></i>
                        <p className="small">Cooperation Model</p>
                    </span>
                </Link>
            </div>
        </div>
    )
}

export default BottomNavbar;
import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";
import ThemeContext from "./context/ThemeContext";
import UnialRoutes from "./UnialRoutes";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("unialsolutions"));
root.render(
  <ThemeContext>
    <BrowserRouter>
      <HelmetProvider>
        <UnialRoutes />
      </HelmetProvider>
    </BrowserRouter>
  </ThemeContext>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React from 'react';
import './Portfolio.css';
import { Link } from 'react-router-dom';

const AnimatedButton = ({ btnText, url }) => {
  return (
    <>
        <Link to={url} style={{textDecoration: 'none'}}>
            <div className='d-flex align-items-center gap-2 animated-button position-relative'>
                <div className='button-circle position-relative'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="31" fill="#7f0000" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M1 8a.5.5 0 0 1 .5-.5h11.793l-3.147-3.146a.5.5 0 0 1 .708-.708l4 4a.5.5 0 0 1 0 .708l-4 4a.5.5 0 0 1-.708-.708L13.293 8.5H1.5A.5.5 0 0 1 1 8z"/>
                    </svg>
                </div>
                <p className='m-0 animated-button-text'>{btnText}</p>
            </div>
        </Link>
    </>
  )
}

export default AnimatedButton
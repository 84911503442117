import React from "react";
import { Link } from "react-router-dom";

const ServiceCooperate = ({ api, currentMode, currentColor }) => {
  return (
    <>
      <section className="main-service-padding mb-3" id="servicecooperation">
        <div className="container">
          <div className="d-flex align-items-center justify-content-center text-center mb-5">
            <h3
              className={`${
                currentMode === "Dark" ? "text-white" : "text-dark"
              }`}
            >
              Our Cooperation Model
            </h3>
          </div>
          <div className="row gy-5">
            {api.map((val, i) => (
              <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                <div
                  className={`card cooperate-cards shadow-sm ${
                    currentMode === "Dark"
                      ? "bg-black cooperate-card-border"
                      : "bg-white cooperate-card-border-white"
                  }`}
                  style={{ borderTop: `17px solid ${currentColor.color}` }}
                >
                  <img
                    src={
                      "https://www.scnsoft.com/software-development-services/python-devs/service-options-python-devs_dedicated-team.svg"
                    }
                    className="card-img-top"
                    width={100}
                    height={100}
                    alt={"img/cooperation"}
                  />
                  <div className="card-body cooperate-cards-body">
                    <h5
                      className={`text-center ${
                        currentMode === "Dark" ? "text-white" : "text-black"
                      }`}
                      style={{ fontWeight: "900" }}
                    >
                      {val.cooperationmodel_title}
                    </h5>
                    <ul className="list-style-none">
                      <div
                        className={`text-center ${
                          currentMode === "Dark" ? "text-white" : "text-black"
                        }`}
                        dangerouslySetInnerHTML={{
                          __html: val.cooperationmodel_content,
                        }}
                      ></div>
                    </ul>
                  </div>
                  <div className="d-flex align-items-center px-3">
                    <Link
                      to={`/contactus`}
                      className="w-100"
                      style={{ textDecoration: "none" }}
                    >
                      <button
                        type="button"
                        className="footerupperSectionMainBtn"
                        style={{
                          width: "100%",
                          background: currentColor.color,
                          border: `1px solid ${currentColor.color}`,
                        }}
                      >
                        {val.cooperationmodel_button}
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceCooperate;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Helmet } from "react-helmet-async";
import { useStateContext } from "../../../context/ThemeContext";
import Navbar from "../../../components/Header/Navbar/Navbar";
import Footer from "../../Footer/Footer";
import shape1 from "../../../assets/banner_shape01.png";
import shape2 from "../../../assets/banner_shape02.png";
import shape3 from "../../../assets/banner_shape03.png";
import "./MainService.css";
import blockchainIcon from "../../../assets/blockchainIcon.png";
import banner_shape03 from "../../../assets/banner_shape03.png";
import ServiceDevelop from "./mainservices/ServiceDevelop";
import ServiceTechnologies from "./mainservices/ServiceTechnologies";
import ServicePortfolios from "./mainservices/ServicePortfolios";
import ServiceCooperate from "./mainservices/ServiceCooperate";
import MainServiceDoubleNav from "./mainservices/MainServiceDoubleNav";
import Certificaitons from "../Certificaitons";
import MainServiceMobileNav from "./mainservices/MainServiceMobileNav";
import { useNavigate } from "react-router-dom";

const BlockchainPage = () => {
  const { currentMode, currentColor } = useStateContext();
  const [selectedweDevelopAPI, setSelectedWeDevelopAPI] = useState([]);
  const [servicesState, setServicesState] = useState([]);
  const [porfolioAPI, setPortfoliosAPI] = useState([]);
  const [cooperateModel, setCooperateModel] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const navigate = useNavigate();

  /**
   * Here Ids are hard coded
   */
  useEffect(() => {
    Promise.all([
      axios.get(`${global.Unial_BackendURL}/applications/unique`),
      axios.get(`${global.Unial_BackendURL}/services`),
      axios.get(`${global.Unial_BackendURL}/portfolios/distinct`),
      axios.get(`${global.Unial_BackendURL}/cooperationmodels/distinct`),
      axios.get(`${global.Unial_BackendURL}/seo/blockchainpage`),
    ])
      .then((responses) => {
        // console.log(responses[2]?.data);
        const filtered = responses[0]?.data?.filter((x) => {
          return x.applications_service_id === 19;
        });
        setSelectedWeDevelopAPI(filtered);

        // console.log(responses[1]?.data)
        const service = responses[1]?.data?.filter(
          (x) => x?.services_category === 19
        );
        setServicesState(service);

        // console.log(responses[2].data)
        const portfolio = responses[2]?.data?.filter(
          (x) => x.portfolios_services_id === 19
        );
        setPortfoliosAPI(portfolio);

        // console.log(responses[3].data, 'res')
        const cooperate = responses[3].data?.filter(
          (x) => x.cooperationmodel_service_id === 19
        );
        setCooperateModel(cooperate);
        // console.log(porfolioAPI, 'portfolio')

        setMetaData(responses[4].data);
      })
      .catch((error) => error);
  }, []);
  // console.log(metaData);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <title>{metaData[0]?.page_title}</title>
        <meta
          name="keywords"
          content={
            metaData?.length > 0
              ? JSON.parse(metaData[0]?.meta_keywords)?.join(", ")
              : null
          }
        />
        <meta name="desciption" content={metaData[0]?.page_description} />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content={metaData[0]?.meta_title} />
        <meta
          name="twitter:description"
          content={metaData[0]?.meta_description}
        />
        <meta
          property="og:description"
          content={metaData[0]?.meta_description}
        />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://unialsolutions.com" />
      </Helmet>
      <Navbar />
      <MainServiceDoubleNav />
      <MainServiceMobileNav currentMode={currentMode} />
      <main
        className={`blockchain-service ${
          currentMode === "Dark" ? "bg-dark-mode" : "bg-white-mode"
        }`}
      >
        <section className="banner-area blockchain-banner-bg">
          <div className="blockcahin-banner-shape-wrap">
            <img src={shape1} alt="img/banner" className="img-one" />
            <img src={shape2} alt="img/banner" className="img-two" />
            <img src={shape3} alt="img/banner" className="img-three" />
          </div>
          <div className="container">
            <div className="banner-content">
              <h2 className="title text-white">
                Rock the Blockchain Development with UNIAL SOLUTIONS
              </h2>
              <p className="text-white text-center mt-3">
                UNIAL Blockchain development services empower your decentralized
                application with secure and efficient smart contracts. Our
                expert developers use the Blockchain programming language to
                create customized Ethereum-based solutions that meet your
                specific business needs. Contact us today to discuss your
                project requirements.
              </p>
              <button
                type="button"
                onClick={() => navigate("/contactus")}
                className="we-button mt-4"
              >
                Get Started
              </button>
            </div>
          </div>
        </section>
        <section className="main-service-padding" id="servicehire">
          <div className="container">
            <div className="row who-we-row">
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="who-we-img position-relative d-flex align-items-center justify-content-center">
                  <img
                    src={banner_shape03}
                    alt="shape/img"
                    width={100}
                    height={100}
                    className="shape-img"
                  />
                  <img
                    src={blockchainIcon}
                    alt="blockchainIcon/png"
                    className="blockchainimg"
                  />
                </div>
              </div>
              <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                <div className="who-we-content">
                  <h6
                    className={`${
                      currentMode === "Dark" ? "text-white" : "text-black"
                    } text-uppercase`}
                  >
                    Who We Are
                  </h6>
                  <h1
                    className={`${
                      currentMode === "Dark" ? "text-white" : "text-black"
                    }`}
                  >
                    The World’s 1st ICO Platform That Offers Rewards
                  </h1>
                  <p
                    className={`${
                      currentMode === "Dark" ? "text-white" : "text-black"
                    }`}
                  >
                    The World’s 1st ICO Platform That Offers Rewards and The
                    platform helps investors to make easy to purchase and sell
                    their tokens
                  </p>
                  <button
                    type="button"
                    onClick={() => navigate("/contactus")}
                    className="we-button mt-4"
                  >
                    Purchase Token
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <Certificaitons ifCenter currentMode={currentMode} />
        {selectedweDevelopAPI?.length > 0 ? (
          <ServiceDevelop
            api={selectedweDevelopAPI}
            currentMode={currentMode}
          />
        ) : null}
        {servicesState?.length > 0 ? (
          <ServiceTechnologies api={servicesState} currentMode={currentMode} />
        ) : null}
        {porfolioAPI?.length > 0 ? (
          <ServicePortfolios api={porfolioAPI} currentMode={currentMode} />
        ) : null}
        {cooperateModel?.length > 0 ? (
          <ServiceCooperate
            currentColor={currentColor}
            api={cooperateModel}
            currentMode={currentMode}
          />
        ) : null}
      </main>
      <Footer />
    </>
  );
};

export default BlockchainPage;


// { name: "slate", color: "#020617", isColor: "#1f2937" },
// { name: "black", color: "#000", isColor: "#DA0909" },
export const ColorOptions = [
  { name: "wine", color: "#7B0000", isColor: "#DA0909" },
  { name: "red", color: "#ef4444", isColor: "#000" },
  { name: "orange", color: "#f97316", isColor: "#000" },
  { name: "amber", color: "#f59e0b", isColor: "#000" },
  { name: "yellow", color: "#eab308", isColor: "#000" },
  { name: "lime", color: "#84cc16", isColor: "#000" },
  { name: "green", color: "#22c55e", isColor: "#000" },
  { name: "emeralad", color: "#10b981", isColor: "#000" },
  { name: "teal", color: "#14b8a6", isColor: "#000" },
  { name: "cyan", color: "#06b6d4", isColor: "#000" },
  { name: "sky", color: "#0ea5e9", isColor: "#000" },
  { name: "blue", color: "#3b82f6", isColor: "#000" },
  { name: "indigo", color: "#6366f1", isColor: "#000" },
  { name: "violet", color: "#8b5cf6", isColor: "#000" },
  { name: "purple", color: "#a855f7", isColor: "#000" },
  { name: "fuchsia", color: "#d946ef", isColor: "#000" },
  { name: "pink", color: "#ec4899", isColor: "#000" },
  { name: "rose", color: "#f43f5e", isColor: "#000" },
];

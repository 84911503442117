import React, { useEffect, useState } from "react";
import { Link } from "react-scroll";
import "../../../components/Header/Navbar/Navbar.css";
import axios from "axios";

const BottomNavBlog = () => {
  const [categoryState, setCategoryState] = useState([]);
  useEffect(() => {
    axios
      .get(`${global.Unial_BackendURL}/blog/category`)
      .then((res) => setCategoryState(res.data))
      .catch((error) => error);
  }, []);
  return (
    <div className="aiz-mobile-bottom-nav d-xl-none fixed-bottom bg-white shadow-lg border-top w-100">
      <div className="d-flex align-items-center justify-content-around overflow-scroll w-full flex-grow-1">
        {categoryState?.map((cat, i) => (
          <Link
            spy={true}
            key={i}
            smooth={true}
            offset={-20}
            duration={500}
            to={`${cat.categorysection_id}-portfolios`}
            className="text-reset flex-grow-1 text-center py-3 border-right,'bg-soft-primary')}}"
          >
            <span className="d-inline-block position-relative px-2">
            <i className="fas fa-boxes"></i>
              <p style={{
                fontSize: '0.8rem',
                fontWeight: '500'
              }}>{cat.categorysection_name}</p>
            </span>
          </Link>
        ))}
        {/* <Link
          to="ourServices"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          className="text-reset flex-grow-1 text-center py-3 border-right,'bg-soft-primary')}}"
        >
          <span className="d-inline-block position-relative px-2">
            <i class="fa fa-home"></i>
            <p className="small">Our Blogs</p>
          </span>
        </Link>
        <Link
          to="appdevelopes"
          spy={true}
          smooth={true}
          offset={-100}
          duration={500}
          className="text-reset flex-grow-1 text-center py-3 border-right"
        >
          <span className="d-inline-block position-relative px-2">
            <i className="fas fa-receipt"></i>
            <p className="small">We Develop</p>
          </span>
        </Link>
        <Link
          to="portfolio"
          spy={true}
          smooth={true}
          offset={-110}
          duration={500}
          className="text-reset flex-grow-1 text-center py-3 border-right"
        >
          <span className="d-inline-block position-relative px-2">
            <i className="fas fa-boxes"></i>
            <p className="small">Our Portfolio</p>
          </span>
        </Link>
        <Link to='solutionsDeliver' spy={true} smooth={true} offset={-100} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right">
            <span className="d-inline-block position-relative px-2">
                <i className="fas fa-cogs"></i>
                <p className="small">Solutions</p>
            </span>
        </Link>
        <Link to='getReady' spy={true} smooth={true} offset={-50} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right">
            <span className="d-inline-block position-relative px-2">
                <i className="fas fa-boxes"></i>
                <p className="small">Projects</p>
            </span>
        </Link> */}
      </div>
    </div>
  );
};

export default BottomNavBlog;

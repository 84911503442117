import React from "react";
import "../MainService.css";

const ServiceDevelop = ({ api, currentMode }) => {
  const handleClick = (t) => {
    // console.log(t)
    window.open(`${t}`, "_blank");
  };
  return (
    <>
      <section className="main-service-padding" id="servicedevelop">
        <div className="container">
          <div className="d-flex align-items-center justify-content-center text-center mb-5">
            <h3
              className={`${
                currentMode === "Dark" ? "text-white" : "text-dark"
              }`}
            >
              What We Developes
            </h3>
          </div>
          <div className="partner-wrap">
            <ul className="partner-list p-0">
              {api?.map((item, index) => (
                <li
                  key={index}
                  style={{
                    border:
                      currentMode === "Dark"
                        ? "1px solid rgba(255, 255, 255, 0.1)"
                        : "1px solid rgba(0, 0, 0, 0.05)",
                  }}
                  onClick={() => handleClick(item?.applications_shortdesc)}
                >
                  <img
                    src={`${global.Unial_BackendURL}/nodeassets/${item?.applications_logo}`}
                    alt={`img-${index}`}
                    width={65}
                    height={65}
                  />
                  <h6
                    className={
                      currentMode === "Dark" ? "text-white" : "text-black"
                    }
                    style={{fontSize: '0.9rem'}}
                  >
                    {item?.applications_title}
                  </h6>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceDevelop;

import React from 'react';

const Post = ({ img, title, currentMode }) => {
  return (
    <>
        <div className='unial-post'>
            <div className='d-flex align-items-center unial-post-img'>
                <img src={img} alt={"img/post"} />
            </div>
            <div className={`unial-post-body ${currentMode === "Dark" ? "text-white" : "text-black"}`}>
                <h1>{title}</h1>
            </div>
        </div>
    </>
  )
}

export default Post;
import React, { useEffect, useState } from "react";
import axios from "axios";
import "../../../GlobalVariables";
import { Link } from "react-router-dom";
import './blogposts.css';
import AnimatedButton from "../Portfolio/AnimatedButton";
import { useStateContext } from "../../../context/ThemeContext";

const BlogPosts = () => {
  const [blogdata, setBlogData] = useState([]);
  const { currentMode } = useStateContext();

  useEffect(() => {
    axios
      .get(`${global.Unial_BackendURL}/blogsection`)
      .then((responses) => {
        setBlogData(responses.data);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  return (
    <>
      {!blogdata?.length > 0 ? null : <section className="my-5">
        <div className="container">
            <div className="d-grid align-items-center">
                <h1 className="text-black m-0 fw-semibold">Our latest blogs</h1>
                <hr style={{
                    opacity: '1',
                    zIndex: '-1',
                    width: '3rem',
                    border: '1px solid #ff9500'
                }} className="mt-1 mb-4" />
            </div>
          <div className="row gy-4">
            {blogdata?.slice(0, 3)?.map((item, i) => (
              <div
                key={i}
                className="col-lg-4 col-md-6 col-sm-12 col-12"
              >
                <Link
                  to={`/blogs/${item.blogsection_slug}`}
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  <div className="unial-post">
                    <div className="d-flex align-items-center unial-post-img">
                      <img src={`${global.Unial_BackendURL}/nodeassets/${item.blogsection_image}`} alt={"img/post"} />
                    </div>
                    <div className="unial-post-body">
                      <h1 className={`fs-5 ${currentMode === "Dark" ? "text-white" : "text-black"}`}>{item.blogsection_title}</h1>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>
          <div className="d-flex align-items-center justify-content-center my-5">
            <AnimatedButton btnText={'See All Blogs'} url={'/blogs'} />
          </div>
        </div>
      </section>}
    </>
  );
};

export default BlogPosts;

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";
import "../../../GlobalVariables.js";
import Navbar from "../../../components/Header/Navbar/Navbar";
import Footer from "../../Footer/Footer";
import "./Portfoliodetail.css";
import { Helmet } from "react-helmet-async";

const PortfolioDetailPage = () => {
  const ifData = useLocation();
  const details = ifData.state.dataObj;
  // console.log(details);
  const {
    portfolios_id,
    portfolios_title,
    portfolios_shortdesc,
    portfolios_longdesc,
    portfolios_industory,
    portfolios_keywords,
    portfolios_metadescription,
    // portfolios_metatags,
    // portfolios_serialnumber,
    portfolios_featuredimages,
    portfolios_sliderimages,
  } = details;

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);

  useEffect(() => {
    if (portfolios_title && portfolios_title) {
      const slug = portfolios_title?.trim()?.replace(/\s+/g, "-").toLowerCase();
      // update URL with blog post slug
      window.history.replaceState(
        null,
        portfolios_title,
        `/portfolio/${slug}`
      );
    }
    window.scrollTo(0, 0);
  }, [portfolios_title]);

  return (
    <>
      <Helmet>
        <meta property="og:title" content={portfolios_title} />
        <meta
          property="og:description"
          content={portfolios_metadescription}
        />
        <meta
          property="og:image"
          content={`${global.Unial_BackendURL}/nodeassets/${portfolios_featuredimages}`}
        />
        <meta
          property="og:url"
          content={`${global.Unial_BackendURL}/portfolio/${portfolios_id}`}
        />
        <meta property="og:type" content="article" />
        <meta name="description" content={portfolios_metadescription} />
        <meta name="keywords" content={portfolios_keywords + ","} />
      </Helmet>
      <Navbar />
      <main className="portfolio-details">
        <div className="container-fluid">
          <div className="row gy-5">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 mx-lg-auto mx-md-auto mx-sm-auto mx-auto">
              <div className="d-grid align-items-center gap-3 portfolio-details-images">
                <div className="d-flex align-items-center">
                  <img
                    src={`${global.Unial_BackendURL}/nodeassets/${portfolios_featuredimages}`}
                    alt={portfolios_id}
                    className="portfolios-img"
                  />
                </div>
                <div className="d-flex align-items-center">
                  <img
                    src={`${global.Unial_BackendURL}/nodeassets/${portfolios_sliderimages}`}
                    alt={portfolios_id}
                    className="portfolios-img"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 mx-lg-auto mx-md-auto mx-sm-auto mx-auto">
              <div className="portfolio-details-content">
                <h1 className="fs-5 fw-bold m-0">{portfolios_industory}</h1>
                <h1 className="fw-semibold fs-2 details-title">
                  {portfolios_title}
                </h1>
                <p className="text-dark">{portfolios_shortdesc}</p>
                <div className="d-grid align-items-center">
                  <h1 className="fs-5 m-0">{"Description"}</h1>
                  {/* <hr className="line m-0" /> */}
                  <div
                    className="content mt-1"
                    dangerouslySetInnerHTML={{ __html: portfolios_longdesc }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <section style={{ background: "#e7e6e6" }}>
          <div style={{ width: "95%" }} className="mx-auto py-5 mt-5">
            <div className="d-grid align-items-center">
              <h1 className="fs-5 fw-semibold m-0">LET's COLLABORATION</h1>
              <div className="d-flex align-items-center justify-content-between">
                <div className="d-flex align-items-center">
                  <h1 className="fs-1 fw-bold m-0">Got's A Project</h1>
                </div>
                <div className="d-grid align-items-center">
                  <Link
                    to={`/contactus`}
                    className="w-100"
                    style={{ textDecoration: "none" }}
                  >
                    <button type="button" className="footerupperSectionMainBtn">
                      {"Contact Us"}
                      <i className="fa-solid fa-arrow-right-long subTextHeaderIcon"></i>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <hr />
            <div className="d-flex align-items-center">
              <p className="text-dark">
                We’re <b>a team of creatives</b> who are excited about unique
                ideas and help fin-tech
                <br /> companies to <b>create amazing identity</b> by crafting
                top-notch UI/UX.
              </p>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
};

export default PortfolioDetailPage;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useStateContext } from "../../../context/ThemeContext";
import { Row, Tab } from "react-bootstrap";
import "./Section.css";
import { Link } from "react-router-dom";

const ServicesSection = () => {
  const [loading, setLoading] = useState(true);
  const [sectionState, setSectionState] = useState([]);
  const [categoryState, setCategoryState] = useState([]);
  // const [servicesState, setServicesState] = useState([]);
  // const [selectCategory, setSelectCategory] = useState([]);
  const { currentMode, currentColor } = useStateContext();

  const fetchData = () => {
    setLoading(true);
    Promise.all([
      axios.get(`${global.Unial_BackendURL}/services/section`),
      axios.get(`${global.Unial_BackendURL}/service/category`),
    ])
      .then((responses) => {
        // console.log(responses[2]?.data);
        setSectionState(responses[0]?.data);
        setCategoryState(responses[1]?.data);
        setLoading(false);
      })
      .catch((error) => error);
  };

  useEffect(() => {
    fetchData();
  }, []);
  // console.log(categoryState, "categoryState");

  useEffect(() => {
    const adjustCardHeights = () => {
      const cards = document.querySelectorAll(".category-cards");
      let maxHeight = 0;
      cards.forEach((card) => {
        maxHeight = Math.max(maxHeight, card.offsetHeight);
      });
      cards.forEach((card) => {
        card.style.height = `${maxHeight}px`;
      });
    };

    window.addEventListener("load", adjustCardHeights);
    return () => window.removeEventListener("load", adjustCardHeights);
  }, []);

  return (
    <>
      {loading ? null : (
        <div
          className="pimcore_area_services pimcore_area_content"
          id="ourServices"
        >
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="first"
            className="mt-5  a-services waypoint"
          >
            <div className="container">
              {sectionState.map((data, i) => (
                <div key={i}>
                  <h2 className={`a-section__title h2 step-up 1`}>
                    <span
                      className={
                        currentMode === "Dark" ? "text-white" : "text-black"
                      }
                    >
                      {data?.services_sec_title}
                    </span>
                  </h2>
                  <p className="icon-box-editable__description">
                    <span
                      className={
                        currentMode === "Dark" ? "text-white" : "text-black"
                      }
                    >
                      {data?.services_sec_text}
                    </span>
                  </p>
                </div>
              ))}

              <Row className="theme-row mt-4">
                {categoryState?.map((cat, i) => (
                  <div key={i} className="col-lg-3 col-md-4 col-sm-6 col-12">
                    <Link
                      to={`/services/${cat?.category_slugs}`}
                      className="text-decoration-none"
                    >
                      <div className="services_card_mainsection category-cards theme-outline">
                        <div className="d-flex align-items-center justify-content-center">
                          <img
                            src={`${global.Unial_BackendURL}/nodeassets/${cat?.category_image}`}
                            alt="service/name"
                          />
                        </div>
                        <div className="d-grid align-items-center text-center gap-2">
                          <h5 className="m-0 text-center category_title">{cat?.category_name}</h5>
                          <p className="m-0 text-center text-black">
                            {cat?.category_text}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </Row>
            </div>
          </Tab.Container>
        </div>
      )}
    </>
  );
};

export default ServicesSection;

import React from 'react'
import { Link } from 'react-scroll';
import "../../../../components/Header/Navbar/Navbar.css";

const MainServiceMobileNav = ({ currentMode }) => {
    const Themer = currentMode === "Dark" ? "text-white" : "text-black";
    return (
        <div className={`aiz-mobile-bottom-nav d-xl-none fixed-bottom shadow-lg ${currentMode === "Dark" ? "bg-black border-top border-secondary" : "bg-white border-dark border-top"}`}>
            <div className="d-flex justify-content-around align-items-center">
                <Link to='servicehire' spy={true} smooth={true} offset={-100} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right,'bg-soft-primary')}}">
                    <span className="d-inline-block position-relative px-2">
                        <i className={`fa-brands fa-hire-a-helper ${Themer}`}></i>
                        <p className={`small ${Themer}`}>Hire</p>
                    </span>
                </Link>
                <Link to='awards' spy={true} smooth={true} offset={-100} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right">
                    <span className="d-inline-block position-relative px-2">
                    <i className={`fa-solid fa-award ${Themer}`}></i>
                        <p className={`small ${Themer}`}>Awards</p>
                    </span>
                </Link>
                <Link to='servicedevelop' spy={true} smooth={true} offset={-110} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right">
                    <span className="d-inline-block position-relative px-2">
                    <i className={`fa-solid fa-code ${Themer}`}></i>
                        <p className={`small ${Themer}`}>We Develop</p>
                    </span>
                </Link>
                {/* servicetechnologies */}
                <Link to='servicetechnologies' spy={true} smooth={true} offset={-110} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right">
                    <span className="d-inline-block position-relative px-2">
                    <i className={`fa-solid fa-code ${Themer}`}></i>
                        <p className={`small ${Themer}`}>Technologies</p>
                    </span>
                </Link>
                <Link to='serviceportfolio' spy={true} smooth={true} offset={-110} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right">
                    <span className="d-inline-block position-relative px-2">
                    <i className={`${Themer} fas fa-boxes`}></i>
                        <p className={`small ${Themer}`}>Portfolio</p>
                    </span>
                </Link>
                <Link to='servicecooperation' spy={true} smooth={true} offset={-110} duration={500} className="text-reset flex-grow-1 text-center py-3 border-right">
                    <span className="d-inline-block position-relative px-2">
                    <i className={`fa-solid fa-microchip ${Themer}`}></i>
                        <p className={`small ${Themer}`}>Cooperation Model</p>
                    </span>
                </Link>
            </div>
        </div>
    )
}

export default MainServiceMobileNav;
import React from 'react'
// import image1 from "../../../assets/block.png";
import { Link } from 'react-router-dom';

const SectionComp = ({ imgSrc, sId, link }) => {
    // {`/services/${sId}`}
    return (
    <>
        <Link to={link}>
            <img src={`${global.Unial_BackendURL}/nodeassets/${imgSrc}`} className="img-thumbnail imgStyle" alt="image1" height="100px" width="120px" />
        </Link>
    </>
    )
}

export default SectionComp;
import React from "react";
import { useNavigate } from "react-router-dom";

const ServiceTechnologies = ({ api, currentMode }) => {
  const navigate = useNavigate();
  return (
    <>
      <section className="main-service-padding" id="servicetechnologies">
        <div className="container">
          <div className="d-flex align-items-center justify-content-center text-center mb-5">
            <h3
              className={`${
                currentMode === "Dark" ? "text-white" : "text-dark"
              }`}
            >
              Technologies We Work
            </h3>
          </div>
          <div className="row gy-4">
            {api?.map((item, i) => (
              <div
                className="col-lg-2 col-lg-3 col-md-4 col-sm-6 col-12"
                key={i}
              >
                <div className="technologies-work" style={{border: currentMode === "Dark" ? "2px dashed rgba(0, 196, 244, 0.5)" : "2px dashed rgba(0, 0, 0, 0.1)"}}
                  onClick={() => navigate(`/subservices/${item.services_slug}`)}
                >
                  <img
                    src={`${global.Unial_BackendURL}/nodeassets/${item.services_image}`}
                    alt={item.services_slug}
                    width={85}
                    height={85}
                  />
                  <h6 
                    className={
                      currentMode === "Dark" ? "text-white" : "text-black"
                    }
                    style={{fontSize: '0.9rem'}}
                  >
                    {item?.services_name}
                  </h6>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiceTechnologies;

import React from "react";
import { Routes, Route } from "react-router-dom";
import App from "./App";
import { useStateContext } from "./context/ThemeContext";
import Career from "./pages/Career/Career";
import CareerDetails from "./pages/Career/CareerDetails";
import JobPostPage from "./pages/Career/JobPostPage";
import Blogs from "./pages/Blogs/Blogs";
import BlogDetails from "./pages/Blogs/BlogDetails";
import PortfolioDetailPage from "./pages/Home/Portfolio/PortfolioDetailPage";
import Stories from "./pages/Home/Portfolio/Stories/Stories";
import MainServices from "./pages/Home/ServicesSection/MainServices";
import SubServices from "./pages/Home/ServicesSection/services/SubServices";
import Contact from "./pages/ContactUs/Contact";
import Settings from "./components/utils/Settings";

const UnialRoutes = () => {
  const { currentMode } = useStateContext();
  /**
   * *
    useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");
    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }
  }, []);
  *******/
  return (
    <div className={currentMode === "Dark" ? "bg-dark-mode" : "bg-white-mode"}>
      <Settings />
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/careers" element={<Career />} />
        <Route path="/careers/:id" element={<CareerDetails />} />
        <Route path="/careers/postjob" element={<JobPostPage />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:slug" element={<BlogDetails />} />
        <Route path="/portfolio/:id" element={<PortfolioDetailPage />} />
        <Route path="/portfolios" element={<Stories />} />
        <Route path="/services/:slug" element={<MainServices />} />
        <Route path="/subservices/:slug" element={<SubServices />} />
        <Route path="/contactus" element={<Contact />} />
      </Routes>
    </div>
  );
};

export default UnialRoutes;

import React, { useEffect, useState } from "react";
import axios from "axios";
import DropDownComp from "./DropDownComp.jsx";
import "./Dropdown.css";
import { useNavigate } from "react-router-dom";

const Dropdown = ({ currentMode }) => {
  const [categories, setCategories] = useState([]);
  // console.log(categories)
  const [services, setServices] = useState([]);
  // console.log(services)
  const [filteredServices, setFilteredServices] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [activeDiv, setActiveDiv] = useState(false);
  const navigate = useNavigate();
  // const [Drawer, setDrawer] = useState(false);
  // console.log(Drawer)

  useEffect(() => {
    axios
      .get(`${global.Unial_BackendURL}/service/category`)
      .then((res) => setCategories(res.data))
      .catch((error) => error);

    axios
      .get(`${global.Unial_BackendURL}/services`)
      .then((res) => setServices(res.data))
      .catch((error) => error);
  }, []);

  useEffect(() => {
    if (selectedCategory === "All") {
      setFilteredServices(services);
    } else {
      setFilteredServices(
        services.filter((x) => x.services_category === selectedCategory)
      );
      // handleClick()
    }
  }, [selectedCategory, services]);

  const handleClick = (cat) => {
    setActiveDiv(true);
    // console.log(cat)
    setSelectedCategory(cat.category_id);
  };
  const handleClick2 = () => {
    setActiveDiv(false);
    setSelectedCategory("No");
  };
  return (
    <>
      <div className="container-fluid dropDownContainer">
        <div className="row dropDownTechBox">
          <div className="col-lg-2 col-12 dropDownTechBoxRow">
            <ul
              className={`nav nav-pills navbardropDownPills mb-4 flex-column ${currentMode === "Dark" ? "bg-dark-mode shadow-dark-mode-opacity" : "bg-dark-white"}`}
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item w-100">
                <p
                  className={`nav-link ${currentMode === "Dark" ? "dark-link" : "white-link"}`}
                  onClick={() => setSelectedCategory("All")}
                >
                  All
                </p>
              </li>
              {categories?.map((cat, i) => (
                <li className="nav-item w-100" key={i}>
                  <p
                    className={`nav-link ${currentMode === "Dark" ? "dark-link" : "white-link"}`}
                    onClick={() => setSelectedCategory(cat.category_id)}
                  >
                    {cat.category_name}
                  </p>
                </li>
              ))}
            </ul>
          </div>
          <div className={`col-lg-10 col-12 dropDownTechBoxRow`}>
            <div>
              {selectedCategory === "All" ? (
                <div>
                  {categories.map((val, i) => (
                    <div key={i} className="d-grid align-items-start mt-3">
                      <h6 className={`fw-semibold ${currentMode === "Dark" ? "text-white" : "text-dark"}`}>{val.category_name}</h6>
                      <div className="d-flex align-items-start gap-3 flex-wrap mt-1">
                        {services.map((ser, i) =>
                          val.category_id === ser.services_category ? (
                            <div key={i} className="services_box_items d-grid align-items-center" onClick={() => {
                              navigate(`/subservices/${ser.services_slug}`, {replace: true});
                            }}
                            style={{cursor: 'pointer'}}
                            >
                              <img
                                src={`${global.Unial_BackendURL}/nodeassets/${ser.services_image}`}
                                className={`img-thumbnail shadow-sm mb-1`}
                                alt="image1"
                                height="100px"
                                width="100px"
                              />
                              <p className={`service-text ${currentMode === "Dark" ? "text-white" : "text-dark"}`}>{ser.services_name}</p>
                            </div>
                          ) : null
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="d-flex align-items-start gap-3 flex-wrap">
                  {filteredServices.map((service, i) => (
                    <DropDownComp
                      sId={service.services_id}
                      link={`/subservices/${service.services_slug}`}
                      name={service.services_name}
                      imgSrc={service.services_image}
                      key={i}
                      currentMode={currentMode}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>

          <div className="d-flex">
            <div className={`swipeablediv ${activeDiv ? "move-left" : ""}`}>
              <div className="dropDownTechBoxRow2">
                <ul
                  className="nav nav-pills bg-transparent navbardropDownPills mb-4 flex-column"
                  id="pills-tab"
                  role="tablist"
                >
                  {categories?.map((cat, i) => (
                    <li className={`nav-item w-100 ${currentMode === "Dark" ? "bg-black" : "bg-white"}`} key={i}>
                      <p className={`nav-link ${currentMode === "Dark" ? "text-white" : "text-black"}`} onClick={() => handleClick(cat)}>
                        {cat.category_name}
                      </p>
                    </li>
                  ))}
                </ul>
              </div>
            </div>

            <div className={`swipeablediv2 ${activeDiv ? "move-left" : ""}`}>
              <div className="d-flex justify-content-end align-items-end pt-1 pb-2">
                {" "}
                <i
                  className={`fa-solid fa-arrow-left-long swipeableArrow ${currentMode === "Dark" ? "text-white" : "text-black"}`}
                  style={{ fontSize: 20, paddingRight: "43px" }}
                  onClick={handleClick2}
                ></i>
              </div>
              <div>
                {selectedCategory === "All" ? (
                  <div>
                    {/* {categories.map((val, i) => (
                    <div key={i} className="d-grid align-items-start mt-3">
                      <div className="d-flex align-items-start gap-3 flex-wrap mt-1">
                        {services.map((ser, i) => (
                          val.category_id === ser.services_category ? <DropDownComp sId={ser.services_id} link={`/subservices/${service.services_id}`} name={ser.services_name} imgSrc={ser.services_image} key={i} /> : null
                        ))}
                      </div>
                    </div>
                  ))} */}
                  </div>
                ) : (
                  <div
                    className="d-flex align-items-start flex-wrap"
                    style={{ width: "330px" }}
                  >
                    {filteredServices.map((service, i) => (
                      // <div className="col-">
                      <DropDownComp
                        sId={service.services_id}
                        link={`/subservices/${service.services_slug}`}
                        name={service.services_name}
                        imgSrc={service.services_image}
                        key={i}
                        currentMode={currentMode}
                      />
                      // </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropdown;

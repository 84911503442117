import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Header/Navbar/Navbar";
import Footer from "../Footer/Footer";
import "./Blogdetails.css";
import linkedin from "../Blogs/assets/linkedin.svg";
import twitter from "../Blogs/assets/twitter.svg";
import facebook from "../Blogs/assets/facebook.svg";
import email from "../Blogs/assets/email.svg";

const BlogDetails = () => {
  const { slug } = useParams();
  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get(`${global.Unial_BackendURL}/blogsection`)
      .then((res) => {
        const filtered = res?.data?.filter((x) => x.blogsection_slug == slug);
        setData(filtered);
      })
      .catch((err) => console.log(err));
  }, []);
  // console.log(data, "data");

  // console.log(data);
  const handleShareLink = (name) => {
    let url = window.location.href;
    url = url.replaceAll(":", "%3A");
    url = url.replaceAll("/", "%2F");
    switch (name) {
      case "facebook":
        let ShareUrlFcb = `https://www.facebook.com/sharer/sharer.php?u=${url}`;
        window.open(ShareUrlFcb, "", "height=500,width=500,left=500,top=100");
        break;
      case "mail":
        let ShareUrlMail = `mailto:email@example.com?subject${window.location.pathname.slice(
          9
        )}=&body=${window.location.href}`;
        let modalOfMail = window.open(
          ShareUrlMail,
          "",
          "height=500,width=500,left=500,top=100"
        );
        setTimeout(() => {
          modalOfMail.close();
        }, 2000);
        break;
      case "twitter":
        let ShareUrlTwitter = `https://twitter.com/share?url=${url}`;
        window.open(
          ShareUrlTwitter,
          "",
          "height=500,width=500,left=500,top=100"
        );
        break;
      case "linkedin":
        let ShareUrlLinkedIn = `https://www.linkedin.com/shareArticle?url=${url}&mini=true`;
        window.open(
          ShareUrlLinkedIn,
          "",
          "height=500,width=500,left=500,top=100"
        );
        break;
    }
  };

  /*****
   * if (data[0]?.blogsection_title && data[0]?.blogsection_title) {
      const slug = data[0]?.blogsection_title
        ?.trim()
        ?.replace(/\s+/g, "-")
        .toLowerCase();
      window.history.replaceState(
        null,
        data[0]?.blogsection_title,
        `/blogs/${slug}`
      );
    }
   * 
   * ****/

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Helmet>
        <title>{data[0]?.blogsection_title}</title>
        <meta
          name="description"
          content={data[0]?.blogsection_metadescription}
        />
        <meta
          name="keywords"
          content={
            data.length ? JSON.parse(data[0]?.blogsection_metakeywords) : null
          }
        />
        <link
          rel="canonical"
          href={`${global.WebsiteUrl}/blogs/${data[0]?.blogsection_slug}`}
        />
        <meta name="robots" content="index,follow" />
        <meta name="application-name" content="Unial Solutions" />
        <link rel="author" href={`${global.WebsiteUrl}`} />
        <meta name="generator" content="Unial Solutions" />
        <meta name="creator" content="Unial Solutions" />
        <meta name="publisher" content="Unial Solutions" />
        <meta
          name="googlebot"
          content="max-video-preview:-1, max-image-preview:large, max-snippet:-1"
        />

        <meta property="og:type" content="article" />
        <meta property="og:site_name" content="Unial Solutions" />
        <meta property="og:locale" content="en_US" />
        <meta
          property="og:article:published_time"
          content={data[0]?.blog_timestamps}
        />
        <meta
          property="og:article:modified_time"
          content={data[0]?.blog_timestamps}
        />
        <meta property="og:title" content={data[0]?.blogsection_title} />
        <meta
          property="og:description"
          content={data[0]?.blogsection_metadescription}
        />
        <meta
          property="og:image"
          content={`${global.Unial_BackendURL}/nodeassets/${data[0]?.blogsection_image}`}
        />
        <meta property="og:url" content={`${global.WebsiteUrl}/blogs/${data[0]?.blogsection_slug}`} />
        {/* <meta
          property="og:url"
          content={`${global.WebsiteUrl}/blogs/${data[0]?.blogsection_slug}`}
        /> */}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={data[0]?.blogsection_title} />
        <meta
          name="twitter:description"
          content={data[0]?.blogsection_metadescription}
        />
        <meta
          name="twitter:image"
          content={`${global.Unial_BackendURL}/nodeassets/${data[0]?.blogsection_image}`}
        />
      </Helmet>
      {data && data?.length > 0 ? (
        <section style={{ marginTop: "7vh" }}>
          <div className="container">
            <div className="blog-details-hero">
              <div className="blog-details-hero-title">
                <div className="d-flex align-items-center gap-3 mb-1">
                  <div
                    onClick={() => handleShareLink("linkedin")}
                    className="socialicon-buttons"
                  >
                    <img
                      width={17}
                      height={17}
                      className="img-fluid"
                      src={linkedin}
                      alt="linkedin/svg"
                    />
                  </div>
                  <div
                    onClick={() => handleShareLink("twitter")}
                    className="socialicon-buttons"
                  >
                    <img
                      width={17}
                      height={17}
                      className="img-fluid"
                      src={twitter}
                      alt="twitter/svg"
                    />
                  </div>
                  <div
                    onClick={() => handleShareLink("facebook")}
                    className="socialicon-buttons"
                  >
                    <img
                      width={17}
                      height={17}
                      className="img-fluid"
                      src={facebook}
                      alt="facebook/svg"
                    />
                  </div>
                  <div
                    onClick={() => handleShareLink("mail")}
                    className="socialicon-buttons"
                  >
                    <img
                      width={17}
                      height={17}
                      className="img-fluid"
                      src={email}
                      alt="mail/svg"
                    />
                  </div>
                </div>
                <div className="d-grid align-items-center">
                  <h1>{data[0]?.blogsection_title}</h1>
                </div>
                <div className="blog-details-hero-img d-grid align-items-center">
                  <img
                    src={`${global.Unial_BackendURL}/nodeassets/${data[0]?.blogsection_image}`}
                    alt={data[0].blogsection_title}
                  />
                </div>
                <div className="py-5">
                  <div
                    className="content"
                    dangerouslySetInnerHTML={{
                      __html: data[0]?.Blogsection_content,
                    }}
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : null}
      <Footer />
    </>
  );
};

export default BlogDetails;
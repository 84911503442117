import React from "react";
import "./Portfolio.css";
import AnimatedButton from "./AnimatedButton";
import directmail from "../../../assets/directmail.png";
import directcall from "../../../assets/directcall.png";
const ActionCall = ({ currentMode }) => {
  return (
    <>
      <div style={{ marginBottom: "5vh" }}>
        <div className={`accelerated-box theme-outline position-relative d-flex align-items-center justify-content-center flex-column gap-3 text-center ${currentMode === "Dark" ? "bg-black shadow-dark-mode" : "bg-white"}`}>
          <div className="actioncall-icon-content-left">
            <a
              target={"_blank"}
              className="icon-content-left text-decoration-none"
              href={`https://api.whatsapp.com/send?phone=16282292879`}
            >
              <span style={{color: '#25d06f'}} className="fw-semibold">Whatsapp<br/>here:</span>
              <i style={{color: '#25d06f'}} className="fa-brands fa-whatsapp"></i>
            </a>
          </div>

          <div className="actioncall-icon-content-right">
            <a className="icon-content-right text-decoration-none" target={"_blank"} href="tel:923013949480">
              <span style={{color: '#25d0c8'}} className="fw-semibold">Call<br/>here:</span>
              <img
                src={directcall}
                alt="directcall/png"
                width={31}
                height={31}
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </a>
          </div>

          <div className="actioncall-icon-content-bottom-left">
            <a className="icon-content-bottom-left text-decoration-none" target={"_blank"} href="skype:ceounial">
            <span style={{color: '#0099ff'}} className="fw-semibold">Skype<br/>here:</span>
              <i className="fab fa-skype"></i>
            </a>
          </div>

          <div className="actioncall-icon-content-bottom-right">
            <a
              className="text-decoration-none  icon-content-bottom-right"
              target={"_blank"}
              href="mailto:info@unialsolutions.com"
            >
            <span style={{color: '#7f0000'}} className="fw-semibold">Email<br/>here:</span>
              <img
                src={directmail}
                alt="directcall/png"
                width={27}
                height={27}
                className="img-fluid"
                style={{ objectFit: "contain" }}
              />
            </a>
          </div>

          <h1 className={`accelerated-box-title ${currentMode === "Dark" ? "text-white" : "text-black"}`}>
            The call for accelerated change
          </h1>
          <p className={`accelerated-box-text ${currentMode === "Dark" ? "text-white" : "text-black"}`}>
            What begins with a strong digital core can become much more – we
            call it total enterprise reinvention.
          </p>
          <AnimatedButton btnText={"Explore 2023"} url={"/contactus"} />
        </div>
      </div>
    </>
  );
};

export default ActionCall;

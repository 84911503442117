import React, { useEffect, useState } from "react";
import "./AppCollapse.css";
import axios from "axios";
import { useStateContext } from "../../../context/ThemeContext";

const AppCollapse = () => {
  const [categories, setCategories] = useState([]);
  const [applications, setApplications] = useState([]);
  const [filteredApplications, setFilteredApplications] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [openIndex, setOpenIndex] = useState(-1);
  const { currentMode, currentColor } = useStateContext();

  useEffect(() => {
    axios
      .get(`${global.Unial_BackendURL}/service/category`)
      .then((res) => setCategories(res.data))
      .catch((error) => error);
    axios
      .get(`${global.Unial_BackendURL}/applications/unique`)
      .then((res) => setApplications(res.data))
      .catch((error) => error);
  }, []);

  const handleCollapse = (id) => {
    setFilteredApplications(
      applications.filter((x) => x.applications_service_id === id)
    );
  };

  const handleOpenLink = (t) => {
    // console.log(t)
    window.open(`${t}`, "_blank");
  };

  return (
    <>
      {categories.length > 0 ? (
        <div className="container">
          <div className={`app-develop-collapse theme-outline ${currentMode === "Dark" ? "bg-black shadow-dark-mode":"bg-white"}`} id="appdevelopes">
            <div className="text-center mb-3">
              <h1 className={`app-develop-collapse-title ${currentMode === "Dark" ? "text-white":"text-dark"}`}>
                Applications We Developes
              </h1>
            </div>
            {categories.map((cat, index) => {
              const isOpen = index === openIndex;
              const handleClick = () => {
                setOpenIndex(isOpen ? -1 : index);
              };
              return (
                <div key={index} className="app-collapse-accordion">
                  <div
                    className="app-accordion-trigger shadow-sm"
                    style={{background: currentColor.color}}
                    onClick={() => {
                      handleCollapse(cat.category_id);
                      handleClick();
                    }}
                  >
                    <h6 className="fw-bold m-0 text-white">
                      {cat.category_name}
                    </h6>
                    <div className="d-grid align-items-center">
                      {isOpen ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="#fff"
                          width={22}
                          height={22}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={2}
                          stroke="#fff"
                          width={22}
                          height={22}
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                          />
                        </svg>
                      )}
                    </div>
                  </div>
                  {isOpen && (
                    <div
                      className={
                        !isOpen
                          ? "app-accordion-content"
                          : "app-accordion-content-height"
                      }
                    >
                      <div className="row gy-4 mt-2 mb-3">
                        {filteredApplications?.map((app, i) => (
                          <div
                            className="col-lg-3 col-md-4 col-sm-6 col-12"
                            key={i}
                            style={{cursor: 'pointer'}}
                            onClick={() => handleOpenLink(app?.applications_shortdesc)}
                          >
                            <div className={`app-accordion-content-item shadow-sm ${currentMode === "Dark" ? "bg-black shadow-dark-mode" : "bg-white"}`}>
                              <img
                                src={`${global.Unial_BackendURL}/nodeassets/${app?.applications_logo}`}
                                alt={app?.applications_id}
                              />
                              <h6 className={currentMode === "Dark" ? "text-white" : "text-dark"}>{app?.applications_title}</h6>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </>
  );
};

export default AppCollapse;

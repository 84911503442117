import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { useStateContext } from "../../context/ThemeContext";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const { currentMode } = useStateContext();

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 60000); // update every minute

    return () => clearInterval(intervalId);
  }, []);

  const footerapi = {
    client: {
      title: "For Clients",
      links: [
        { text: "Hire Dedicated Developers", url: "/contactus" },
        { text: "Hire An Agency Developers", url: "/contactus" },
        { text: "Hire In USA", url: "/contactus" },
        { text: "Hire In UK", url: "/contactus" },
        { text: "Hire Certified Blockchain Developers", url: "/contactus" },
        { text: "Hire Certified Rust Developers", url: "/contactus" },
        { text: "Hire Certified AI/GPT Developers", url: "/contactus" },
        { text: "Hire Certified Web Developers", url: "/contactus" },
        { text: "Hire Certified Mobile Developers", url: "/contactus" },
        { text: "Hire Certified Metaverse Developers", url: "/contactus" },
        { text: "Hire Certified Game Developers", url: "/contactus" },
      ],
    },
    talents: {
      title: "For Talent",
      links: [
        { text: "Blockchain Developer", url: "/careers" },
        { text: "Solidity Developer", url: "/careers" },
        { text: "Rust Developer", url: "/careers" },
        { text: "Solana Developer", url: "/careers" },
        { text: "AI/GPT Developer", url: "/careers" },
        { text: "React.js Developer", url: "/careers" },
        { text: "Node.js Developer", url: "/careers" },
        { text: "Next.js Developer", url: "/careers" },
        { text: "MERN Stack Developer", url: "/careers" },
        { text: "Metaverse Developer", url: "/careers" },
        { text: "Game Developer", url: "/careers" },
      ],
    },
    services: {
      title: "Our Services",
      links: [
        { text: "Blockchain Development", url: "/contactus" },
        { text: "Smart Contract Development", url: "/contactus" },
        { text: "Web Apps Development", url: "/contactus" },
        { text: "Mobile Apps Development", url: "/contactus" },
        { text: "Metaverse Development", url: "/contactus" },
        { text: "Game Development", url: "/contactus" },
        { text: "Devops Development", url: "/contactus" },
        { text: "AI/GPT Developer", url: "/contactus" },
        { text: "ChatGPT Integrations", url: "/contactus" },
      ],
    },
    officies: {
      title: "International Officies",
      links: [
        {
          title: "USA Office",
          address: {
            title: "Address",
            class: "usaColor",
            text: "315 Montgomery Street 10th Floor San Francisco, CA 94104",
          },
          phone: {
            title: "Phone",
            num: "+1 628 229 2879",
          },
        },
        {
          title: "UK Office",
          address: {
            title: "Address",
            class: "usaColor",
            text: "71-75 Shelton Street, Covent Garden, London, United Kingdom, WC2H 9JQ",
          },
          phone: {
            title: "Phone",
            num: "+44 7477 179922",
          },
        },
        {
          title: "Pakistan Office",
          address: {
            title: "Address",
            class: "pakColor",
            text: "UNIAL Office, Susan Road, Faisalabad Punjab, Pakistan",
          },
          phone: {
            title: "Phone",
            num: "+92 301 3949480",
          },
        },
      ],
    },
  };

  return (
    <>
      <div className={`container-fluid ${currentMode === "Dark" ? "bg-black shadow-dark-mode" : "footerLowerSectionOuterBox"}`} style={{padding: '5rem 0 1rem 0'}}>
        <div className="container">
          <div className="row gy-4 d-md-flex d-none">
            <div className="col-xl-3 col-lg-6 col-sm-6 col-12 d-flex flex-column">
              <h6 className={currentMode === "Dark" ? "text-white fw-bold" : "fw-bold text-black"}>{footerapi.client.title}</h6>
              {footerapi?.client.links.map((l, i) => (
                <Link to={l.url} key={i} className={currentMode === "Dark" ? "footer-link-dark-mode" : "footerLink"}>
                  {l.text}
                </Link>
              ))}
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 col-12 d-flex flex-column">
              <h6 className={currentMode === "Dark" ? "text-white fw-bold" : "fw-bold text-black"}>{footerapi.talents.title}</h6>
              {footerapi?.talents.links.map((l, i) => (
                <Link to={l.url} key={i} className={currentMode === "Dark" ? "footer-link-dark-mode" : "footerLink"}>
                  {l.text}
                </Link>
              ))}
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 col-12 d-flex flex-column">
              <h6 className={currentMode === "Dark" ? "text-white fw-bold" : "fw-bold text-black"}>{footerapi.services.title}</h6>
              {footerapi?.services.links.map((l, i) => (
                <Link to={l.url} key={i} className={currentMode === "Dark" ? "footer-link-dark-mode" : "footerLink"}>
                  {l.text}
                </Link>
              ))}
            </div>
            <div className="col-xl-3 col-lg-6 col-sm-6 col-12 d-flex flex-column">
              <h6 className={currentMode === "Dark" ? "text-white fw-bold" : "fw-bold text-black"}>{footerapi.officies.title}</h6>
              <div className="d-grid align-items-center gap-3">
                {footerapi?.officies.links.map((l, i) => (
                  <div className="d-grid align-items-center" key={i}>
                    <h6 className={`fw-bold ${l.address.class}`}>{l.title}</h6>
                    <div className="d-flex align-items-start gap-2">
                      <h6 className={`fw-bold text-black ${currentMode === "Dark" ? "text-white fw-bold" : "fw-bold text-black"}`}>{l.address.title}:</h6>
                      <p className="m-0" style={{color: `${currentMode === "Dark" ? "#a5a4a4" : "#000"}`}}>{l.address.text}</p>
                    </div>
                    <div className="d-flex align-items-start gap-2">
                      <h6 className={`fw-bold text-black ${currentMode === "Dark" ? "text-white fw-bold" : "fw-bold text-black"}`}>{l.phone.title}:</h6>
                      <p className="m-0" style={{color: `${currentMode === "Dark" ? "#a5a4a4" : "#000"}`}}>{l.phone.num}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="row d-md-none d-block">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={"fw-bold text-black"}>{footerapi.client.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {footerapi.client.links.map((l, i) => (
                  <Link to={l.url} key={i} className={currentMode === "Dark" ? "footer-link-dark-mode" : "footerLink"}>
                    {l.text}
                  </Link>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={"fw-bold text-black"}>{footerapi.talents.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {footerapi.talents.links.map((l, i) => (
                  <Link to={l.url} key={i} className={currentMode === "Dark" ? "footer-link-dark-mode" : "footerLink"}>
                    {l.text}
                  </Link>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={"fw-bold text-black"}>{footerapi.services.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {footerapi.services.links.map((l, i) => (
                  <Link to={l.url} key={i} className={currentMode === "Dark" ? "footer-link-dark-mode" : "footerLink"}>
                    {l.text}
                  </Link>
                ))}
              </AccordionDetails>
            </Accordion>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography className={"fw-bold text-black"}>{footerapi.officies.title}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className="d-grid align-items-center gap-3">
                  {footerapi?.officies.links.map((l, i) => (
                    <div className="d-grid align-items-center" key={i}>
                      <h6 className={`fw-bold ${l.address.class}`}>
                        {l.title}
                      </h6>
                      <div className="d-flex align-items-start gap-2">
                        <h6 className="fw-bold text-black">
                          {l.address.title}:
                        </h6>
                        <p className="m-0">{l.address.text}</p>
                      </div>
                      <div className="d-flex align-items-start gap-2">
                        <h6 className="fw-bold text-black">{l.phone.title}:</h6>
                        <p className="m-0">{l.phone.num}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
          <div className="row">
            <div className="col-md-10 col-12">
              <div className="d-flex align-items-center gap-3 mt-4">
                <h6
                  style={{ margin: "0", fontWeight: "bold", color: "#7F0000" }}
                >
                  Follow Us
                </h6>
                <a href="https://www.facebook.com/unialsolutions">
                  <i className="fa-brands footerSocialIcon fa-facebook"></i>
                </a>
                <a href="https://www.instagram.com/unialsolutions/">
                  <i className="fa-brands footerSocialIcon fa-instagram"></i>
                </a>
                <a href="https://www.linkedin.com/company/unialsolutions/">
                  <i className="fa-brands footerSocialIcon fa-linkedin-in"></i>
                </a>
                <a href="https://twitter.com/unialsolutions">
                  <i className="fa-brands footerSocialIcon fa-twitter"></i>
                </a>
                <a href="https://www.youtube.com/channel/UCIBH4do_K3colky9_WsxuFw">
                  <i className="fa-brands footerSocialIcon fa-youtube"></i>
                </a>
                <a href="https://www.behance.net/unialsolutions">
                  <i className="fa-brands footerSocialIcon fa-behance"></i>
                </a>
              </div>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col text-md-center text-start">
              <h6 className={currentMode === "Dark" ? "text-white fw-bold" : "fw-bold text-black"}>
                Copyright © {currentYear}. All rights reserved by UNIAL SOLUTIONS
                (PVT) Limited
              </h6>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;

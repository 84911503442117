import React, { useState, useEffect } from "react";
import axios from "axios";
import "../ServicesSection/Section.css";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";
import { useStateContext } from "../../../context/ThemeContext";

const Certificaitons = ({ ifCenter }) => {
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  // console.log(selectedCertifications)
  const { currentMode } = useStateContext(); 

  useEffect(() => {
    axios
      .get(`${global.Unial_BackendURL}/certifications`)
      .then((res) => {
        const filtered = res?.data?.filter((x) => {
          return x.certification_subservice_id == 95;
        });
        setSelectedCertifications(filtered);
      })
      .catch((error) => console.log(error));
  }, []);

  const goToCertificates = (link) => {
    window.open(link, "_blank");
  };

  const splideOptions = {
    perPage: 5,
    perMove: 1,
    type: "loop",
    rewind: true,
    keyboard: "global",
    autoplay: true,
    gap: "1rem",
    pagination: false,
    breakpoints: {
      1200: { perPage: 3 },
      991: { perPage: 2.3 },
      768: { perPage: 2 },
      500: { perPage: 1 },
    },
  };

  return (
    <>
      {selectedCertifications?.length > 0 ? (
        <div
          className="container"
          style={{ marginTop: "11vh", marginBottom: "11vh" }}
          id="awards"
        >
         <div className={`service-titles mb- ${ifCenter ? "text-center" : "text-start"}`}>
            <h1
              className={`${
                currentMode === "Dark" ? "text-white" : "text-black"
              } fw-bold`}
            >
              Awards
            </h1>
            <hr
              style={{
                opacity: "1",
                zIndex: "-1",
                width: "3rem",
                marginTop: "1.5rem",
                border: "1px solid #ff9500",
              }}
            />
          </div>
          <Splide options={splideOptions}>
            {selectedCertifications?.map((val, i) => (
              <SplideSlide
                key={i}
                className="mt-1 mb-1 d-flex align-items-center justify-content-center slider-brand"
                onClick={() => goToCertificates(val.certification_link)}
              >
                <img
                  src={`${global.Unial_BackendURL}/nodeassets/${val.certification_logo}`}
                  alt="img/slider"
                  width={165}
                  height={165}
                  style={{ objectFit: "contain", cursor: "pointer" }}
                />
              </SplideSlide>
            ))}
          </Splide>
        </div>
      ) : null}
    </>
  );
};

export default Certificaitons;

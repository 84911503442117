import React from "react";
import "../../components/Header/Navbar/Navbar.css";

const ContactBottomNav = () => {
  return (
    <div className="aiz-mobile-bottom-nav d-xl-none fixed-bottom bg-white shadow-lg border-top w-100">
      <div className="d-flex align-items-center justify-content-around overflow-scroll w-full flex-grow-1">
        <a
          href="tel:923013949480"
          target="_blank"
          className="text-reset flex-grow-1 text-center py-3"
        >
          <span className="d-inline-block position-relative px-2">
            <i class="fa fa-phone"></i>
            <p className="small">Direct Call</p>
          </span>
        </a>
        <a
          target={"_blank"}
          href={`https://api.whatsapp.com/send?phone=16282292879`}
          className="text-reset flex-grow-1 text-center py-3"
        >
          <span className="d-inline-block position-relative px-2">
            <i class="fa fa-whatsapp fs-5"></i>
            <p className="small">Whatsapp</p>
          </span>
        </a>
        <a
          href="mailto:info@unialsolutions.com"
          target="_blank"
          className="text-reset flex-grow-1 text-center py-3"
        >
          <span className="d-inline-block position-relative px-2">
            <i class="fa fa-envelope"></i>
            <p className="small">Email</p>
          </span>
        </a>
        <a
          href="skype:ceounial"
          target="_blank"
          className="text-reset flex-grow-1 text-center py-3"
        >
          <span className="d-inline-block position-relative px-2">
            <i class="fab fa-skype fs-5"></i>
            <p className="small">Skype</p>
          </span>
        </a>
      </div>
    </div>
  );
};

export default ContactBottomNav;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useStateContext } from "../../../context/ThemeContext";
import Certificaitons from "../Certificaitons";
import ServiceDevelop from "./mainservices/ServiceDevelop";
import ServiceTechnologies from "./mainservices/ServiceTechnologies";
import ServicePortfolios from "./mainservices/ServicePortfolios";
import ServiceCooperate from "./mainservices/ServiceCooperate";
import Navbar from "../../../components/Header/Navbar/Navbar";
import Footer from "../../Footer/Footer";
import MainServiceDoubleNav from "./mainservices/MainServiceDoubleNav";
import MainServiceMobileNav from "./mainservices/MainServiceMobileNav";
import "./MainService.css";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const WebAppPage = () => {
  const { currentMode, currentColor } = useStateContext();
  const [selectedweDevelopAPI, setSelectedWeDevelopAPI] = useState([]);
  const [servicesState, setServicesState] = useState([]);
  const [porfolioAPI, setPortfoliosAPI] = useState([]);
  const [cooperateModel, setCooperateModel] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const navigate = useNavigate();
  /**
   * Here Ids are hard coded
   */
  useEffect(() => {
    Promise.all([
      axios.get(`${global.Unial_BackendURL}/applications/unique`),
      axios.get(`${global.Unial_BackendURL}/services`),
      axios.get(`${global.Unial_BackendURL}/portfolios/distinct`),
      axios.get(`${global.Unial_BackendURL}/cooperationmodels/distinct`),
      axios.get(`${global.Unial_BackendURL}/seo/webdevelopmentpage`),
    ])
      .then((responses) => {
        // console.log(responses[0]?.data);
        const filtered = responses[0]?.data?.filter((x) => {
          return x.applications_service_id == 20;
        });
        setSelectedWeDevelopAPI(filtered);

        // console.log(responses[1]?.data)
        const service = responses[1]?.data?.filter(
          (x) => x?.services_category === 20
        );
        setServicesState(service);

        // console.log(responses[2].data)
        const portfolio = responses[2]?.data?.filter(
          (x) => x.portfolios_services_id === 20
        );
        setPortfoliosAPI(portfolio);

        // console.log(responses[3].data, "res");
        const cooperate = responses[3].data?.filter(
          (x) => x.cooperationmodel_service_id === 19
        );
        setCooperateModel(cooperate);
        // console.log(porfolioAPI, 'portfolio')
        setMetaData(responses[4].data);
      })
      .catch((error) => error);
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Helmet>
        <title>{metaData[0]?.page_title}</title>
        <meta
          name="keywords"
          content={
            metaData?.length > 0
              ? JSON.parse(metaData[0]?.meta_keywords)?.join(", ")
              : null
          }
        />
        <meta name="desciption" content={metaData[0]?.page_description} />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content={metaData[0]?.meta_title} />
        <meta
          name="twitter:description"
          content={metaData[0]?.meta_description}
        />
        <meta
          property="og:description"
          content={metaData[0]?.meta_description}
        />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://unialsolutions.com" />
      </Helmet>
      <Navbar />
      <MainServiceDoubleNav />
      <MainServiceMobileNav currentMode={currentMode} />
      <main className="web-services" id="servicehire">
        <div className="container">
          <div className="row web-services-row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 d-flex align-items-center">
              <div className="web-services-text d-flex flex-column align-items-start gap-3">
                <div className="d-grid align-items-center">
                  <h1
                    className={`fw-bold ${
                      currentMode === "Dark" ? "text-white" : "text-black"
                    }`}
                  >
                    Let's Build
                  </h1>
                  <h1
                    className={`fw-bold ${
                      currentMode === "Dark" ? "text-white" : "text-black"
                    }`}
                  >
                    Amazing Web Apps
                  </h1>
                </div>
                <p
                  className={`${
                    currentMode === "Dark" ? "text-white" : "text-black"
                  } fw-normal`}
                >
                  Building Web is not big thing, converting it into business
                  does matter. We develop your custom Web solutions, which
                  follow the HCI, SEO complete procedures, we re UNIAL believe
                  in code optimisation to get maximum output, to stand out your
                  business in top.
                </p>
                <button
                  type="button"
                  onClick={() => navigate(`/contactus`)}
                  className="we-button"
                >
                  Get Started
                </button>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="d-flex align-items-center web-services-img">
                <img
                  src="https://vie-alpha.vercel.app/img/mobile-app/header-img.png"
                  alt="img/png"
                />
              </div>
            </div>
          </div>
        </div>
      </main>
      <Certificaitons ifCenter currentMode={currentMode} />
      {selectedweDevelopAPI?.length > 0 ? (
        <ServiceDevelop api={selectedweDevelopAPI} currentMode={currentMode} />
      ) : null}
      {servicesState?.length > 0 ? (
        <ServiceTechnologies api={servicesState} currentMode={currentMode} />
      ) : null}
      {porfolioAPI?.length > 0 ? (
        <ServicePortfolios api={porfolioAPI} currentMode={currentMode} />
      ) : null}
      {cooperateModel?.length > 0 ? (
        <ServiceCooperate
          currentColor={currentColor}
          api={cooperateModel}
          currentMode={currentMode}
        />
      ) : null}
      <Footer />
    </>
  );
};

export default WebAppPage;

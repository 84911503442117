import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/splide/css";
import axios from "axios";
import Navbar from "../../../../components/Header/Navbar/Navbar";
import Footer from "../../../Footer/Footer";
import "../Section.css";
import "../../../../GlobalVariables.js";
import { Helmet } from "react-helmet-async";
import DoubleNavbar from "./SubServiceNav";
import SubServiceBottomNav from "./SubServiceNav/SubServiceBottomNav";
import { useStateContext } from "../../../../context/ThemeContext";
import ServiceDevelop from "../mainservices/ServiceDevelop";
import ServicePortfolios from "../mainservices/ServicePortfolios";
import ServiceCooperate from "../mainservices/ServiceCooperate";

const SubServices = () => {
  const { slug } = useParams();
  // console.log(slug)
  const [serviceDetail, setServiceDetails] = useState([]);
  const [selectedCertifications, setSelectedCertifications] = useState([]);
  const [selectedweDevelopAPI, setSelectedWeDevelopAPI] = useState([]);
  const [selectedCooperateModel, setSelectedCooperateModel] = useState([]);
  const [porfolioAPI, setPortfoliosAPI] = useState([]);
  const { currentMode, currentColor } = useStateContext();
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(`${global.Unial_BackendURL}/servicedetails`)
      .then((res) => {
        // console.log(res.data)
        const filtered = res?.data?.filter((x) => {
          return x.services_slug === slug;
        });
        setServiceDetails(filtered);
      })
      .catch((error) => console.log(error));
  }, [slug]);

  useEffect(() => {
    axios
      .get(`${global.Unial_BackendURL}/certifications`)
      .then((res) => {
        const filtered = res?.data?.filter((x) => {
          return x.certification_subservice_id == serviceDetail[0]?.services_id;
        });
        setSelectedCertifications(filtered);
      })
      .catch((error) => console.log(error));

    // console.log(serviceDetail[0]?.services_id, 'service')

    axios
      .get(`${global.Unial_BackendURL}/applicationdevelop`)
      .then((res) => {
        const filtered = res?.data?.filter((x) => {
          return x.applications_subservice_id == serviceDetail[0]?.services_id;
        });
        setSelectedWeDevelopAPI(filtered);
      })
      .catch((error) => console.log(error));

    axios
      .get(`${global.Unial_BackendURL}/portfolios`)
      .then((res) => {
        // console.log(res.data, 'portfolio')
        const filtered = res?.data?.filter(
          (x) => x.portfolios_subservices_id == serviceDetail[0]?.services_id
        );
        setPortfoliosAPI(filtered);
      })
      .catch((error) => console.log(error));

    axios
      .get(`${global.Unial_BackendURL}/cooperationmodels`)
      .then((res) => {
        const filtered = res?.data?.filter(
          (x) =>
            x.cooperationmodel_subservice_id == serviceDetail[0]?.services_id
        );
        setSelectedCooperateModel(filtered);
      })
      .catch((error) => console.log(error));

    // console.log(`serviceDetail`, serviceDetail[0]?.services_id);
  }, [serviceDetail[0]?.services_id]);

  const handleClick = (t) => {
    // console.log(t)
    window.open(`${t}`, "_blank");
  };

  const goToCertificates = (link) => {
    window.open(link, "_blank");
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const splideOptions = {
    perPage: 5,
    perMove: 1,
    type: "loop",
    rewind: true,
    keyboard: "global",
    autoplay: true,
    gap: "1rem",
    pagination: false,
    breakpoints: {
      1200: { perPage: 3 },
      991: { perPage: 2.3 },
      768: { perPage: 2 },
      500: { perPage: 1 },
    },
  };
  const splideOptions2 = {
    perPage: 1,
    perMove: 1,
    type: "loop",
    rewind: true,
    keyboard: "global",
    pagination: false,
  };

  // console.log(serviceDetail, 'serviceDetail')/
  return (
    <>
      <Helmet>
        {serviceDetail?.map((item) => {
          return (
            <>
              <title>{item.servicesdetails_pageheading}</title>
              <meta
                name="description"
                content={item.servicesdetails_metadescription}
              />
              <meta
                name="keywords"
                content={item.servicesdetails_metatitle + ","}
              />
            </>
          );
        })}
      </Helmet>
      <Navbar />
      <DoubleNavbar />
      <SubServiceBottomNav />
      <main style={{ marginTop: "9vh" }}>
        <div className="container">
          {serviceDetail?.length > 0 ? (
            <>
              {serviceDetail?.map((val, i) => (
                <div key={i}>
                  <div className="d-grid align-items-center">
                    <h1
                      className={
                        currentMode === "Dark" ? "text-white" : "text-black"
                      }
                    >
                      {val.servicesdetails_serviceheading}
                    </h1>
                    <p
                      className={
                        currentMode === "Dark" ? "text-white" : "text-black"
                      }
                    >
                      {val.servicesdetails_servicetext}
                    </p>
                  </div>
                  <div
                    className={`shadow-sm mt-5 p-4 gap-5 d-flex align-items-start company-box ${
                      currentMode === "Dark"
                        ? "bg-black shadow-dark-mode-opacity"
                        : "bg-white"
                    }`}
                    id="servicehire"
                  >
                    <div className="d-grid align-items-center w-25 company-box-img">
                      <img
                        src={`${global.Unial_BackendURL}/nodeassets/${val.servicesdetails_image}`}
                        alt="img"
                        className="img-fluid"
                      />
                    </div>
                    <div className="d-grid align-items-start w-75 company-text-box">
                      <h1
                        className={
                          currentMode === "Dark" ? "text-white" : "text-black"
                        }
                      >
                        {val.servicesdetails_actionline}
                      </h1>
                      <div
                        className={
                          currentMode === "Dark" ? "text-white" : "text-black"
                        }
                        dangerouslySetInnerHTML={{
                          __html: val.servicesdetails_actionlinetext,
                        }}
                      ></div>
                      <Link
                        to={"/contactus"}
                        style={{ textDecoration: "none" }}
                      >
                        <button
                          type="button"
                          className="footerupperSectionMainBtn"
                          style={{background: currentColor.color, border: `1px solid ${currentColor.color}`}}
                        >
                          {val.servicesdetails_actionlinebtn}
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : null}

          {selectedCertifications?.length > 0 ? (
            <>
              <div style={{ marginTop: "9vh" }} id="serviceawards">
                <div className="service-titles">
                  <h1
                    className={
                      currentMode === "Dark" ? "text-white" : "text-black"
                    }
                  >
                    Awards
                  </h1>
                  <hr />
                </div>
                <Splide options={splideOptions}>
                  {selectedCertifications?.map((val, i) => (
                    <SplideSlide
                      key={i}
                      className="mt-1 mb-1 d-flex align-items-center justify-content-center slider-brand"
                      onClick={() => goToCertificates(val.certification_link)}
                    >
                      <img
                        src={`${global.Unial_BackendURL}/nodeassets/${val.certification_logo}`}
                        alt="img/slider"
                        width={165}
                        height={165}
                        style={{ objectFit: "contain", cursor: "pointer" }}
                      />
                    </SplideSlide>
                  ))}
                </Splide>
              </div>
            </>
          ) : null}

          {selectedweDevelopAPI?.length > 0 ? (
            <ServiceDevelop
              api={selectedweDevelopAPI}
              currentMode={currentMode}
            />
          ) : null}

          {porfolioAPI?.length > 0 ? (
            <ServicePortfolios api={porfolioAPI} currentMode={currentMode} />
          ) : null}

          {selectedCooperateModel?.length > 0 ? (
            <ServiceCooperate
              api={selectedCooperateModel}
              currentMode={currentMode}
              currentColor={currentColor}
            />
          ) : null}
        </div>
      </main>
      <Footer />
    </>
  );
};

export default SubServices;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Tab, Row, Col, Nav, Dropdown } from "react-bootstrap";
// import SectionComp from "./services/SectionComp";
// import SubSectionComp from "./services/SubSectionComp";
import SectionComp from "../services/SectionComp.jsx";
import SubSectionComp from "../services/SubSectionComp.jsx";
import '../../../../GlobalVariables.js'
import { useStateContext } from "../../../../context/ThemeContext";
// import { Helmet } from "react-helmet-async";

const ServicesV1 = () => {
  const [loading, setLoading] = useState(true);
  const [sectionState, setSectionState] = useState([]);
  const [categoryState, setCategoryState] = useState([]);
  const [servicesState, setServicesState] = useState([]);
  const [selectCategory, setSelectCategory] = useState([]);
  const { currentMode, currentColor } = useStateContext();

  useEffect(() => {
    Promise.all([
      axios.get(`${global.Unial_BackendURL}/services/section`),
      axios.get(`${global.Unial_BackendURL}/service/category`),
      axios.get(`${global.Unial_BackendURL}/services`),
    ])
      .then((responses) => {
        // console.log(responses[2]?.data);
        setSectionState(responses[0]?.data);
        setCategoryState(responses[1]?.data);
        setServicesState(responses[2]?.data);
        setLoading(false);
      })
      .catch((error) => error);
  }, []);

  const filterServices = (id) => {
    const service = servicesState.filter((x) => x?.services_category === id);
    setSelectCategory(service);
  };

  const cArr = [
    "first",
    "second",
    "third",
    "four",
    "five",
    "six",
    "seven",
    "eight",
  ];
  // console.log(categoryState, 'categoryState')

  return (
    <>
      {/* <Helmet>
        {servicesState?.map((item) => {
          return (
            <>
              <meta
                name="description"
                content={item.services_metadescription}
              />
              <meta name="keywords" content={item.services_keywords + ","} />
            </>
          );
        })}
      </Helmet> */}
      {loading ? null : (
        <div
          className="pimcore_area_services pimcore_area_content mt-5"
          id="ourServices"
        >
          <Tab.Container
            id="left-tabs-example"
            defaultActiveKey="first"
            className="mt-5  a-services waypoint"
          >
            <div className="container">
              {sectionState.map((data, i) => (
                <div key={i}>
                  <h2 className={`a-section__title h2 step-up 1`}>
                    <span
                      className={
                        currentMode === "Dark" ? "text-white" : "text-black"
                      }
                    >
                      {data?.services_sec_title}
                    </span>
                  </h2>
                  <p className="icon-box-editable__description">
                    <span
                      className={
                        currentMode === "Dark" ? "text-white" : "text-black"
                      }
                    >
                      {data?.services_sec_text}
                    </span>
                  </p>
                </div>
              ))}
              <div className="container show-on-mobile my-4">
                <Dropdown>
                  <Dropdown.Toggle
                    id="dropdown-basic"
                    style={{
                      backgroundColor: currentColor?.color,
                      border: currentMode === "Dark" ? `1px solid ${currentColor?.color}` : `1px solid ${currentColor?.color}`,
                    }}
                  >
                    {sectionState?.map((val, i) => (
                      <Dropdown.Item key={i} eventKey={cArr[i]}>
                        {val.services_title}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className={`dropdownMenuSecton ${currentMode === "Dark" ? "bg-black shadow-dark-mode-opacity" : "bg-white"}`}>
                    {categoryState.map((s, index) => (
                      <Dropdown.Item
                        key={index}
                        eventKey={cArr[index + 1]}
                        onClick={() => filterServices(s.category_id)}
                      >
                        <span className={currentMode === "Dark" ? "text-white" : "text-black"}>{s.category_name}</span>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <Row className={`container mt-5 d-flex a-services__tabs pb-3 step-in ${currentMode === "Dark" ? "bg-black shadow-dark-mode" : "bg-white"}`}>
                <Col className="a-services__tabs-container services-pager step-in-left col-md-4 order-md-0" style={{background: currentColor?.color}}>
                  <Nav
                    variant="pills"
                    className="flex-column a-services__tabs-container-ul"
                  >
                    {sectionState?.map((val, i) => (
                      <Nav.Link
                        eventKey={cArr[i]}
                        key={i}
                        className="a-services__tabs-label"
                      >
                        <p>{val.services_title}</p>
                      </Nav.Link>
                    ))}
                    {categoryState?.map((val, i) => (
                      <Nav.Link
                        eventKey={cArr[i + 1]}
                        key={i}
                        onClick={() => filterServices(val.category_id)}
                        className="a-services__tabs-label"
                      >
                        <p>{val.category_name}</p>
                      </Nav.Link>
                    ))}
                  </Nav>
                </Col>
                <div className="a-services__content-block step-in-right col-md-7 p-0">
                  <Col className="a-services__content-container">
                    <Tab.Content className="a-services__content active">
                      {sectionState?.map((val, i) => (
                        <Tab.Pane key={i} eventKey={cArr[i]}>
                          <h5 className={`a-services__name ${currentMode === "Dark" ? "text-white" : "text-black"}`}>
                            {val.services_title}
                          </h5>
                          <p className={`a-services__description ${currentMode === "Dark" ? "text-white" : "text-black"}`}>
                            {val.services_text}
                          </p>
                          <div>
                            {categoryState.map((val, i) => (
                              <SectionComp
                                imgSrc={val.category_image}
                                sId={val.category_id}
                                key={i}
                                link={`/services/${val.category_slugs}`}
                              />
                              // <img src={`${global.Unial_BackendURL}/nodeassets/${val.category_image}`} key={i} />
                            ))}
                          </div>
                        </Tab.Pane>
                      ))}
                      {categoryState?.map((val, catIndex) => (
                        <Tab.Pane key={catIndex} eventKey={cArr[catIndex + 1]}>
                          <h5 className={`a-services__name ${currentMode === "Dark" ? "text-white" : "text-black"}`}>
                            {val.category_name}
                          </h5>
                          <p className={`a-services__description ${currentMode === "Dark" ? "text-white" : "text-black"}`}>
                            {val.category_text}
                          </p>
                          <div>
                            {selectCategory?.map((val, i) => (
                              <SubSectionComp
                                imgSrc={val.services_image}
                                sId={val.services_id}
                                slug={val.services_slug}
                                key={i}
                              />
                              // <img src={`${global.Unial_BackendURL}/nodeassets/${val.services_image}`} key={i} />
                            ))}
                          </div>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Col>
                </div>
              </Row>
            </div>
          </Tab.Container>
        </div>
      )}
    </>
  );
};

export default ServicesV1;

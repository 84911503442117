import React, { useEffect, useState } from "react";
import axios from "axios";
import "./StoryDoubleNavbar.css";
import { Link } from "react-scroll";
import "../../../../GlobalVariables";

const StoryDoubleNavbar = () => {
  const [categoryState, setCategoryState] = useState([]);
  useEffect(() => {
    axios
      .get(`${global.Unial_BackendURL}/service/category`)
      .then((res) => setCategoryState(res.data))
      .catch((error) => error);
  }, []);
  return (
    <div className="container doubleNavBox d-xl-block d-none">
      <div className="d-flex align-items-center overflow-scroll">
        {categoryState?.map((cat, i) => (
          <>
            <Link
              key={i}
              spy={true}
              offset={-150}
              duration={500}
              to={`${cat.category_id}-portfolios`}
              activeClass="navItemActive"
              className="doubleNavItems"
            >
              {cat.category_name}
            </Link>
          </>
        ))}
      </div>
    </div>
  );
};

export default StoryDoubleNavbar;

import React from 'react'
import { useLocation } from "react-router-dom";

const CareerDetails = () => {
    const ifData = useLocation();
  const item = ifData.state.dataObj;
  console.log(item);
  const {
    jobpost_title,
    jobpost_shortText,
    jobpost_workexperience,
    jobpost_deadline,
    jobpost_metaTitle,
    jobpost_metaTags,
    jobpost_metaKeywords,
    jobpost_metaDescription,
    jobpost_education,
  } = item;
  return (
    <>CareerDetails</>
  )
}

export default CareerDetails
import React, { useEffect } from "react";
import confetti from "canvas-confetti";
import logo from "./assets/unial-logo.png";
import { useNavigate } from "react-router-dom";
import "./Successpage.css";

const SuccessPage = ({ setOpenState }) => {
  const navigate = useNavigate();

  const phoneNumber = "16282292879"; // replace with your own phone number

  function onContactUs() {
    window.open(`https://api.whatsapp.com/send?phone=${phoneNumber}`, "_blank");
  }

  useEffect(() => {
    const onConfettiShot = () => {
      var end = Date.now() + 3 * 1000;

      var colors = ["#22c55e", "#d946ef", "#f97316"];

      (function frame() {
        confetti({
          particleCount: 3,
          angle: 60,
          spread: 200,
          origin: { x: 0 },
          colors: colors,
        });
        confetti({
          particleCount: 3,
          angle: 120,
          spread: 200,
          origin: { x: 1 },
          colors: colors,
        });

        if (Date.now() < end) {
          requestAnimationFrame(frame);
        }
      })();
    };
    onConfettiShot();
  }, []);
  return (
    <>
      <main className="succes-main">
        <div className="brand-img">
          <img src={logo} alt={"logo/img"} className="logo" />
        </div>
        <div className="title">
          <h1 className="title-child-3">CONGRATULATIONS</h1>
          <div className="d-flex align-items-center gap-4">
            <h1 className="title-child-1">THANK</h1>{"  "}
            <h1 className="title-child-2">YOU</h1>
          </div>
        </div>
        <div className="d-flex align-items-center justify-center gap-3 column-on-md">
          <button
            className="footerupperSectionMainBtn success-button active-button"
            onClick={() => {
              navigate("/", { replace: true });
              setOpenState(false);
            }}
          >
            BACK TO UNIAL
          </button>
          <button
            className="footerupperSectionMainBtn success-button active-button"
            onClick={onContactUs}
          >
            QUICK CONTACT / <i className="fa-brands fa-whatsapp ms-1 fa-whatsApp"></i>
          </button>
        </div>
      </main>
      {/* <button className="gif-button" onClick={handleConffeti}>
        <img src={gifemoji} alt="gifemoji" className="gif" />
      </button> */}
    </>
  );
};

export default SuccessPage;

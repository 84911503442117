import React, { createContext, useContext, useState } from "react";

export const ContextCreator = createContext();

/***
 * const [currentColor, setCurrentColor] = useState(localStorage.getItem('colorMode') || '#7B0000');
 * const [currentMode, setCurrentMode] = useState(localStorage.getItem('themeMode') || 'Light');
 * localStorage.setItem("colorMode", color);
 * localStorage.setItem("themeMode", mode);
 ****/

const ThemeContext = ({ children }) => {
  const defaultColor = { name: "wine", color: "#7B0000", isColor: "#DA0909" };
  const [currentColor, setCurrentColor] = useState(defaultColor);
  const [currentMode, setCurrentMode] = useState("Light");
  const [themeSettings, setThemeSettings] = useState(false);

  const setMode = (e) => {
    const mode = e.target.value;
    setCurrentMode(mode);
  };

  const setColor = (color) => {
    // console.log(color, 'color')
    setCurrentColor(color);
  };

  return (
    <ContextCreator.Provider
      value={{
        currentColor,
        currentMode,
        setCurrentColor,
        setCurrentMode,
        setMode,
        setColor,
        themeSettings,
        setThemeSettings,
      }}
    >
      {children}
    </ContextCreator.Provider>
  );
};

export default ThemeContext;

export const useStateContext = () => useContext(ContextCreator);

import React, { useEffect, useState } from "react";
import axios from "axios";
import { useStateContext } from "../../../context/ThemeContext";
import WaterWave from "react-water-wave";
import Navbar from "../../../components/Header/Navbar/Navbar";
import MainServiceDoubleNav from "./mainservices/MainServiceDoubleNav";
import MainServiceMobileNav from "./mainservices/MainServiceMobileNav";
import Certificaitons from "../Certificaitons";
import ServiceDevelop from "./mainservices/ServiceDevelop";
import ServiceTechnologies from "./mainservices/ServiceTechnologies";
import ServicePortfolios from "./mainservices/ServicePortfolios";
import ServiceCooperate from "./mainservices/ServiceCooperate";
import Footer from "../../Footer/Footer";
import waterimgage from "../../../assets/metaverseimg.jpg";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet-async";

const MetaAppPage = () => {
  const { currentMode, currentColor } = useStateContext();
  const [selectedweDevelopAPI, setSelectedWeDevelopAPI] = useState([]);
  const [servicesState, setServicesState] = useState([]);
  const [porfolioAPI, setPortfoliosAPI] = useState([]);
  const [cooperateModel, setCooperateModel] = useState([]);
  const [metaData, setMetaData] = useState([]);
  const navigate = useNavigate();
  /**
   * Here Ids are hard coded
   */
  useEffect(() => {
    Promise.all([
      axios.get(`${global.Unial_BackendURL}/applications/unique`),
      axios.get(`${global.Unial_BackendURL}/services`),
      axios.get(`${global.Unial_BackendURL}/portfolios/distinct`),
      axios.get(`${global.Unial_BackendURL}/cooperationmodels/distinct`),
      axios.get(`${global.Unial_BackendURL}/seo/metaversepage`),
    ])
      .then((responses) => {
        // console.log(responses[0]?.data);
        const filtered = responses[0]?.data?.filter((x) => {
          return x.applications_service_id == 27;
        });
        setSelectedWeDevelopAPI(filtered);

        // console.log(responses[1]?.data)
        const service = responses[1]?.data?.filter(
          (x) => x?.services_category === 27
        );
        setServicesState(service);

        // console.log(responses[2].data)
        const portfolio = responses[2]?.data?.filter(
          (x) => x.portfolios_services_id === 27
        );
        setPortfoliosAPI(portfolio);

        // console.log(responses[3].data, "res");
        const cooperate = responses[3].data?.filter(
          (x) => x.cooperationmodel_service_id === 19
        );
        setCooperateModel(cooperate);
        // console.log(porfolioAPI, 'portfolio')
        setMetaData(responses[4].data);
      })
      .catch((error) => error);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Helmet>
        <title>{metaData[0]?.page_title}</title>
        <meta
          name="keywords"
          content={
            metaData?.length > 0
              ? JSON.parse(metaData[0]?.meta_keywords)?.join(", ")
              : null
          }
        />
        <meta name="desciption" content={metaData[0]?.page_description} />
        <meta name="robots" content="index,follow" />
        <meta property="og:title" content={metaData[0]?.meta_title} />
        <meta
          name="twitter:description"
          content={metaData[0]?.meta_description}
        />
        <meta
          property="og:description"
          content={metaData[0]?.meta_description}
        />
        <meta property="og:type" content="website" />
        <link rel="canonical" href="https://unialsolutions.com" />
      </Helmet>
      <Navbar />
      <MainServiceDoubleNav />
      <MainServiceMobileNav currentMode={currentMode} />
      <main className="" id="servicehire">
        <WaterWave id={"ripple"} imageUrl={waterimgage}>
          {(methods) => (
            <div className="container">
              <div className="d-flex align-items-center justify-content-center flex-column">
                <h1 className="text-white">Let's Build.</h1>
                <h1 className="text-white">Metaverse Development.</h1>
                <p className="text-white">
                  We help you launch a future-ready Metaverse by supporting the
                  technical and development needs of your project. With our
                  expertise in blockchain, augmented reality, and virtual
                  reality, we design and develop metaverse components like 3D
                  virtual spaces, metaverse NFT marketplaces, metaverse
                  applications, and decentralized platforms.
                </p>
                <div className="d-flex align-items-center gap-3">
                  <button
                    type="button"
                    onClick={() => navigate(`/contactus`)}
                    className="btn btn-light"
                  >
                    Download Apps
                  </button>
                  <button
                    type="button"
                    onClick={() => navigate(`/contactus`)}
                    className="btn btn-outline-light"
                  >
                    Get More Details
                  </button>
                </div>
              </div>
            </div>
          )}
        </WaterWave>
      </main>
      <Certificaitons ifCenter currentMode={currentMode} />
      {selectedweDevelopAPI?.length > 0 ? (
        <ServiceDevelop api={selectedweDevelopAPI} currentMode={currentMode} />
      ) : null}
      {servicesState?.length > 0 ? (
        <ServiceTechnologies api={servicesState} currentMode={currentMode} />
      ) : null}
      {porfolioAPI?.length > 0 ? (
        <ServicePortfolios api={porfolioAPI} currentMode={currentMode} />
      ) : null}
      {cooperateModel?.length > 0 ? (
        <ServiceCooperate
          currentColor={currentColor}
          api={cooperateModel}
          currentMode={currentMode}
        />
      ) : null}
      <Footer />
    </div>
  );
};

export default MetaAppPage;

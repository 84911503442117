import React from "react";
import "./DoubleNavbar.css";
import { Link } from "react-scroll";

const DoubleNavbar = () => {
  return (
    <div className="container doubleNavBox d-xl-block d-none">
      <div className="row">
        <div className="col d-flex justify-content-start align-items-center">
          <Link
            to="ourServices"
            activeClass="navItemActive"
            spy={true}
            offset={-150}
            duration={500}
            className="doubleNavItems"
          >
            Our Services
          </Link>
          <Link
            to="appdevelopes"
            activeClass="navItemActive"
            spy={true}
            offset={-150}
            duration={500}
            className="doubleNavItems"
          >
            We Develop
          </Link>
          <Link
            to="portfolio"
            activeClass="navItemActive"
            spy={true}
            offset={-150}
            duration={500}
            className="doubleNavItems"
          >
            Our Portfolio
          </Link>
        </div>
      </div>
    </div>
  );
};

export default DoubleNavbar;
